import { DriveStep } from 'driver.js';
import { Component } from 'vue';
import { Feature } from './generic';

export interface FTXSlide {
  key: string;
  title: string;
  description: string;
  imageSlide?: Component;
  imageDescription?: string;
}

export interface FTXView {
  show: boolean;
}

export enum FTXPage {
  WELCOME = 'welcome',
  CHALLENGES = 'challenges',
  PRIZES = 'prizes',
  SURVEYS = 'surveys',
}

export enum DriveElements {
  HOME = 'home',
  CHALLENGES = 'challenges',
  LEADERBOARD = 'leaderboard',
  PROFILE = 'profile',
  STORE = 'store',
  QRCODE = 'qrcode',
  USERS = 'users',
  MORE = 'more',
  METRICS = 'metrics',
  DATA_IMPORT = 'data',
  PUBLIC_DISPLAYS = 'public-displays',
  SURVEYS = 'surveys',
  SETTINGS = 'settings',
}

export interface JoyrideButtonsLabel {
  next?: string;
  previous: string;
  done: string;
}

export interface FTXDriveStep extends DriveStep {
  value?: Feature;
}
