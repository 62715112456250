import { defineStore } from 'pinia';
import publicDisplaysService from '@/shared/services/publicDisplaysService/publicDisplaysService';
import { PinInformation } from '@/shared/types/publicDisplays';

interface State {
  pinInformation: PinInformation;
  randomPinInformation: PinInformation;
}

export default defineStore({
  id: 'publicDisplays',

  state: (): State => ({
    pinInformation: {} as PinInformation,
    randomPinInformation: {} as PinInformation,
  }),

  actions: {
    async loadPinInformation() {
      try {
        this.pinInformation = await publicDisplaysService.getPinInformation();
      } catch (error) {
        this.pinInformation = {} as PinInformation;
      }
    },

    editPin(pin: string) {
      return publicDisplaysService.editPin(pin);
    },

    async loadRandomPinInformation() {
      try {
        const response = await publicDisplaysService.getRandomPinInformation();
        this.pinInformation = response;
      } catch (error) {
        this.pinInformation = {} as PinInformation;
      }
    },

    async togglePinPublicDisplays(activePin: boolean): Promise<void> {
      await publicDisplaysService.togglePinPublicDisplays(activePin);
    },
  },
});
