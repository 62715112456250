import { PostHogInterface } from '@vaibe-github-enterprise/kd-kse-fe-commons';

export interface EventsTracking {
  modeChallenge?: string;
  typeChallenge?: string;
  initialTimeEvent?: string;
  endTimeEvent?: string;
  durationInSeconds?: number;
  motiveFailed?: string;
  motiveWarning?: string;
  identifier?: string;
  ftxPage?: string;
  gameType?: string;
  gameMode?: string;
  b2cId?: string;
}

export interface ManagerPostHog extends PostHogInterface {
  orgId: string;
  b2cId: string;
}

export enum PostHogEvents {
  DSH_TIME_BUTTONS = 'dsh_time_buttons',
  DSH_TOOLTIP_HOVER = 'dsh_tooltip_hover',
  ADD_NEW_CHALLENGE = 'add_new_challenge',
  CANCEL_ADD_NEW_CHALLENGE = 'cancel_add_new_challenge',
  COMPLETE_ADD_NEW_CHALLENGE = 'complete_add_new_challenge',
  FTX_DISMISS_INITIAL_MANAGER = 'ftx_dismiss_initial_manager',
  FTX_DISMISS_JOYRIDE_MANAGER = 'ftx_dismiss_joyride_manager',
  FTX_REVISIT_TOUR_MANAGER = 'ftx_revisit_tour_manager',
  ADD_NEW_DATA_INPUT = 'add_new_data_input',
  CANCEL_NEW_DATA_INPUT = 'cancel_new_data_input',
  COMPLETE_NEW_DATA_INPUT = 'complete_new_data_input',
  FAILED_NEW_DATA_INPUT = 'failed_new_data_input',
  DELETE_SUCCESS_DATA_INPUT = 'delete_success_data_input',
  DUPLICATE_CHALLENGE = 'duplicate_challenge',
  ERROR_DUPLICATE_CHALLENGE = 'error_duplicate_challenge',
  ERROR_LOADING_DUPLICATE_CHALLENGE = 'error_loading_duplicate_challenge',
  ERROR_LOGIN_MANAGER = 'error_login_manager',
  MANAGER_CHANGE_PASSWORD = 'manager_change_password',
  MANAGER_DELETE_ACCOUNT = 'manager_delete_account',
  FINISH_NOW_CHALLENGE = 'finish_now_challenge',
  CANCEL_CHALLENGE = 'cancel_challenge',
  CHALLENGE_SELECTION = 'challenge_selection',
  EXPAND_WIDGET = 'expand_widget',
  CHALLENGE_NAV_TABS = 'challenge_nav_tabs',
}
