import { defineStore } from 'pinia';
import { RouteLocationNormalized } from 'vue-router';

export default defineStore({
  id: 'shared',

  state: () => ({
    loaderState: false,
    loaderTarget: '',
    loaderTransparent: false,
    sharedConfirmationModal: false,
    confirmationModalTitle: '',
    confirmationModalDescription: '',
    confirmationModalPath: '',
    abortController: {} as AbortController,
    loaderText: '',
    confirmationModalDiscardLabel: '',
    confirmationModalCancelLabel: '',
  }),

  actions: {
    showConfirmationModal(): void {
      this.sharedConfirmationModal = true;
    },

    handlerBeforeRouteLeave(
      title: string,
      description: string,
      to: RouteLocationNormalized,
      next: (param?: boolean) => void,
      cancelLabel = '',
      discardLabel = '',
    ): void {
      if (to.query?.bypass === 'true') {
        next();
      } else {
        this.confirmationModalTitle = title;
        this.confirmationModalDescription = description;
        this.confirmationModalCancelLabel = cancelLabel;
        this.confirmationModalDiscardLabel = discardLabel;
        this.confirmationModalPath = to.path;
        this.showConfirmationModal();
        next(false);
      }
    },

    setLoader(
      loaderState: boolean,
      loaderTarget?: string,
      loaderTransparent = false,
      loaderText = '',
    ) {
      this.loaderState = loaderState;
      this.loaderTarget = loaderTarget || '';
      this.loaderTransparent = loaderTransparent;
      this.loaderText = loaderText;
    },

    setAbortController(abortController: AbortController) {
      this.abortController = abortController;
    },

    cancelRequests() {
      if (this.abortController) {
        this.abortController.abort();
      }
    },
  },
});
