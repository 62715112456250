import './styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import {
  symRoundedCheckCircle,
  symRoundedInfo,
  symRoundedReport,
  symRoundedWarning,
  symRoundedClose,
} from '@quasar/extras/material-symbols-rounded';

Notify.registerType('warning', {
  icon: symRoundedWarning,
  classes: 'app-snack-bar app-snack-bar--warning',
});

Notify.registerType('danger', {
  icon: symRoundedReport,
  classes: 'app-snack-bar app-snack-bar--danger',
});

Notify.registerType('success', {
  icon: symRoundedCheckCircle,
  classes: 'app-snack-bar app-snack-bar--success',
});

Notify.registerType('info', {
  icon: symRoundedInfo,
  classes: 'app-snack-bar app-snack-bar--info',
});

Notify.setDefaults({
  position: 'bottom-left',
  group: false,
  timeout: 5000,
  actions: [{ icon: symRoundedClose, padding: '0' }],
  textColor: undefined,
});

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: [Notify],
};
