
import { defineComponent } from 'vue';
import IconSpinner from '@/assets/icons/icon-spinner.vue';

const __default__ = defineComponent({
  name: 'AppLoader',

  components: {
    IconSpinner,
  },

  props: {
    state: {
      type: Boolean,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    asset: {
      type: String,
      default: undefined,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      top: '',
      height: '',
      left: '',
      width: '',
      transitionDuration: 500,
      intervalId: undefined as number | undefined,
      timeoutId: undefined as number | undefined,
    };
  },

  computed: {
    transition(): string {
      return `opacity ${this.transitionDuration}ms`;
    },
  },

  watch: {
    state: {
      immediate: true,
      handler(newVal): void {
        if (newVal) {
          this.intervalId = setInterval(this.updatePosition, 20);
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
          }
        } else {
          this.resetLoader();

          this.timeoutId = setTimeout(() => {
            this.left = '';
            this.top = '';
            this.width = '';
            this.height = '';
            this.timeoutId = undefined;
          }, this.transitionDuration);
        }
      },
    },
  },

  mounted(): void {
    window.addEventListener('resize', this.onWindowResize);
  },

  beforeUnmount(): void {
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    updatePosition(): void {
      const boundingClientRect = document
        .querySelector(this.target)
        ?.getBoundingClientRect();

      if (!this.state) {
        this.resetLoader();
      } else {
        this.left = `${boundingClientRect?.left || 0}px`;
        this.top = `${boundingClientRect?.top || 0}px`;
        this.width = `${boundingClientRect?.width || 0}px`;
        this.height = `${boundingClientRect?.height || 0}px`;
      }
    },

    onWindowResize(): void {
      if (this.state) this.updatePosition();
    },

    resetLoader(): void {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      }
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2fe81936": (_ctx.top),
  "71b06517": (_ctx.height),
  "196eaff7": (_ctx.left),
  "15041bd6": (_ctx.width),
  "2a706d76": (_ctx.transition)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__