import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-863d3bea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-loader"
}
const _hoisted_2 = { class: "loading-animation__contents" }
const _hoisted_3 = {
  key: 0,
  class: "loading-animation__background"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSpinner = _resolveComponent("IconSpinner")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.state)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass([
          'app-spinner__container',
          { 'app-spinner__container--transparent': _ctx.transparent },
        ])
            }, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.asset)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                  : _createCommentVNode("", true),
                _createVNode(_component_IconSpinner, {
                  class: _normalizeClass([
              'loading-animation__spinner',
              { 'loading-animation__spinner--asset': _ctx.asset },
            ])
                }, null, 8, ["class"]),
                (_ctx.asset)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      class: "loading-animation__asset",
                      src: _ctx.asset,
                      alt: "animation-asset"
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                (_ctx.text)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.text), 1))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}