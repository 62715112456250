import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';
import {
  UserStatus,
  User,
  UserType,
  NuvoUserRow,
  Operator,
  GetAllValidOperatorsResponse,
  UsersSites,
  LoadUsersRequest,
  LoadUsersResponse,
  UsersCount,
} from '@/shared/types/user';
import { defaultItemIllustration } from '@/shared/constants/icons';
import { mockGetTeams, team } from '../teams/mockTeams';

export const metadata: ResponseMetadata = {
  totalItems: 8,
  lastUpdated: 1595030400000,
};

export const SiteUSer = {
  name: 'Lisbon Site',
  reference: '9a1c026f-5b35-4323-b0b4-3bc5a1a3f765',
  city: 'Lisbon',
  country: 'Portugal',
  timezone: {
    utcOffset: 'UTC+1',
    ianaTimezone: 'Europe/Lisbon',
  },
};

export const users: Array<User> = [
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.ACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[0].reference,
      name: team[0].name,
    },
    numberOfChallenges: 2,
    teamNames: 'teste',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.INACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd123',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    numberOfChallenges: 0,
    teamNames: 'teste',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.ACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd456',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[0].reference,
      name: team[0].name,
    },
    numberOfChallenges: 0,
    teamNames: 'teste',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.INACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd789',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[0].reference,
      name: team[0].name,
    },
    numberOfChallenges: 0,
    teamNames: 'teste',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.ACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd111',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[1].reference,
      name: team[1].name,
    },
    numberOfChallenges: 0,
    teamNames: 'teste',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.INACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd222',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[1].reference,
      name: team[1].name,
    },
    numberOfChallenges: 0,
    teamNames: 'teste',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.ACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd333',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[1].reference,
      name: team[1].name,
    },
    numberOfChallenges: 0,
    teamNames: 'teste',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.INACTIVE,
    userTypeStaticReference: UserType.OPERATOR,
    reference: 'abcd445',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[2].reference,
      name: team[2].name,
    },
    numberOfChallenges: 2,
    teamNames: 'Team1, Team2',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.DISABLED,
    userTypeStaticReference: UserType.MANAGER,
    reference: 'abcd446',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[2].reference,
      name: team[2].name,
    },
    numberOfChallenges: 2,
    teamNames: 'Team1, Team2',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.DELETED,
    userTypeStaticReference: UserType.MANAGER,
    reference: 'abcd447',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[2].reference,
      name: team[2].name,
    },
    numberOfChallenges: 2,
    teamNames: 'Team1, Team2',
    site: SiteUSer,
    coins: 0,
  },
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    lastEntrance: 1631210400000,
    userStatusStaticReference: UserStatus.INVITED,
    userTypeStaticReference: UserType.MANAGER,
    reference: 'abcd448',
    privacyPolicy: true,
    termsAndConditions: true,
    avatarUrl: defaultItemIllustration,
    team: {
      reference: team[2].reference,
      name: team[2].name,
    },
    numberOfChallenges: 2,
    teamNames: 'Team1, Team2',
    site: SiteUSer,
    coins: 0,
  },
];

export const exampleValidateUsers: NuvoUserRow[] = [
  {
    row: 1,
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    siteExternalReference: 'teste',
    userTypeStaticReference: UserType.OPERATOR,
  },
];

export const exampleRegistrateUsers = [
  {
    name: 'John Doe',
    orgUserId: '321654987',
    email: 'iamagero@tribehero.com',
    userTypeStaticReference: UserType.OPERATOR,
  },
];

export const getUsers = (
  pagination?: LoadUsersRequest,
): ApiResponse<LoadUsersResponse> => {
  const page = Number(pagination?.page);
  const size = Number(pagination?.size);

  const filteredUsers = users.filter(
    (user) =>
      (!pagination?.roles?.length ||
        pagination?.roles?.includes(user.userTypeStaticReference)) &&
      ((!pagination?.teams?.length && !pagination?.noTeams) ||
        pagination?.teams?.includes(user.team?.reference || '') ||
        (pagination?.noTeams && user.team === undefined)) &&
      (!pagination?.query?.length ||
        user.name?.includes(pagination?.query || '')) &&
      (!pagination?.statuses?.length ||
        pagination?.statuses?.includes(user.userStatusStaticReference)),
  );

  const startIndex = size ? (page - 1) * size : 0;
  const endIndex = size ? (page - 1) * size + size : filteredUsers.length;

  return {
    metadata,
    results: {
      users: pagination ? filteredUsers.slice(startIndex, endIndex) : users,
      numberOfUsers: users.length,
      numberOfUsersFiltered: filteredUsers.length,
    },
  };
};

const usersSites: UsersSites[] = [
  {
    siteName: 'teste',
    siteCity: 'teste city',
    siteCountry: 'teste country',
    siteOffset: 'teste offset',
    users: [{ name: 'teste', orgUserId: 'teste', reference: 'teste value' }],
  },
];

export const getUsersSites = () => {
  return {
    metadata,
    results: usersSites,
  };
};

export const getMockedUserCounters = (): UsersCount => {
  return {
    numberOfUsers: getUsers().results.numberOfUsers,
    numberOfTeams: mockGetTeams().results.team.length,
  };
};

export const operators: Operator[] = [
  {
    orgUserId: 'WFE1',
    reference: 'REF_FE1',
    name: 'Worker FE1',
  },
  {
    orgUserId: 'WFE2',
    reference: 'REF_FE2',
    name: 'Worker FE2',
  },
  {
    orgUserId: 'WFE3',
    reference: 'REF_FE3',
    name: 'Worker FE3',
  },
  {
    orgUserId: 'WFE4',
    reference: 'REF_FE4',
    name: 'Worker FE4',
  },
  {
    orgUserId: 'WFE5',
    reference: 'REF_FE5',
    name: 'Worker FE5',
  },
  {
    orgUserId: 'WFE6',
    reference: 'REF_FE6',
    name: 'Worker FE6',
  },
];

export const mockGetAllOperators = () => {
  return {
    metadata,
    results: {
      operators,
    },
  };
};

export const mockGetAllValidOperators = (): GetAllValidOperatorsResponse => ({
  teams: [
    {
      name: 'Team 1',
      orgTeamId: 'orgId1',
      reference: 'team-1-reference',
      operators: [
        {
          orgUserId: '123654',
          name: 'cris1',
        },
        {
          orgUserId: 'lin23',
          name: 'Lincoln',
        },
        {
          orgUserId: 'test45678',
          name: 'test45678',
        },
        {
          orgUserId: 'Teste 2',
          name: 'Teste 2',
        },
        {
          orgUserId: 'WQA1',
          name: 'Worker QA1',
        },
        {
          orgUserId: 'WQA10',
          name: 'Worker QA10',
        },
      ],
    },
    {
      name: 'Team 2',
      orgTeamId: 'orgId4',
      reference: 'team-2-reference',
      operators: [
        {
          orgUserId: 'WQA12',
          name: 'Worker QA12',
        },
        {
          orgUserId: 'WQA2',
          name: 'Worker QA2',
        },
      ],
    },
  ],
  operatorsWithNoTeams: [
    {
      orgUserId: 'WQA6',
      name: 'Worker QA6',
    },
    {
      orgUserId: 'WQA7',
      name: 'Worker QA7',
    },
  ],
});

export const mockUserSites = [
  {
    siteName: 'lisbon site',
    siteCity: 'lisbon',
    siteCountry: 'portugal',
    siteOffset: 'utc+00',
    users: [
      { name: 'user1', reference: 'ref-user1', orgUserId: 'orgid1' },
      { name: 'user2', reference: 'ref-user2', orgUserId: 'orgi2' },
      { name: 'user3', reference: 'ref-user3', orgUserId: 'orgid3' },
    ],
  },
  {
    siteName: 'porto site',
    siteCity: 'porto',
    siteCountry: 'portugal',
    siteOffset: 'utc+00',
    users: [{ name: 'user4', reference: 'ref-user4', orgUserId: 'orgid4' }],
  },
];
