<template>
  <AppModal
    v-model="sharedStore.sharedConfirmationModal"
    no-route-dismiss
    persistent
  >
    <template #header>{{ confirmationModalTitle }}</template>
    <template #body>{{ confirmationModalDescription }}</template>
    <template #footer="{ dismiss }">
      <AppButton
        ref="cancelButton"
        type="empty"
        data-testid="cancel-modal"
        :label="confirmationModalCancelLabel"
        @click="dismiss"
      />
      <AppButton
        ref="discardButton"
        type="filled"
        data-testid="dont-save-modal"
        :label="confirmationModalDiscardLabel"
        @click="onDiscardClick(dismiss)"
      />
    </template>
  </AppModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import useSharedStore from '@/store/shared/useSharedStore';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';

export default defineComponent({
  name: 'AppGlobalConfirmationModal',

  components: { AppModal, AppButton },

  computed: {
    ...mapStores(useSharedStore),

    confirmationModalTitle(): string {
      return this.sharedStore.confirmationModalTitle;
    },

    confirmationModalDescription(): string {
      return this.sharedStore.confirmationModalDescription;
    },

    confirmationModalCancelLabel(): string {
      return this.sharedStore.confirmationModalCancelLabel === ''
        ? this.$t('labels.common.cancel')
        : this.sharedStore.confirmationModalCancelLabel;
    },

    confirmationModalDiscardLabel(): string {
      return this.sharedStore.confirmationModalDiscardLabel === ''
        ? this.$t('labels.common.discard')
        : this.sharedStore.confirmationModalDiscardLabel;
    },
  },

  methods: {
    onDiscardClick(callback: () => void): void {
      this.$router.push({
        path: this.sharedStore.confirmationModalPath,
        query: { bypass: 'true' },
      });
      callback();
    },
  },
});
</script>
