import { PositionGamesDistribution } from '@/shared/types/games';

export const getGameDistributionCoins = (): PositionGamesDistribution => ({
  participation: 0.8,
  firstPlace: 4,
  secondPlace: 8,
  thirdPlace: 10,
  totalPlayers: 30,
  total: 50,
});

export const getGameDistributionCoinsTogetherAsTeam =
  (): PositionGamesDistribution => ({
    participation: 0.8,
    win: 4,
    loss: 8,
    totalPlayers: 30,
    total: 50,
  });

export const getGameDistributionCoinsDuel = (): PositionGamesDistribution => ({
  participation: 0.8,
  win: 40,
  loss: 0,
  totalPlayers: 30,
  total: 50,
});
