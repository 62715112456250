import useTokenStore from '@/store/token/useTokenStore';
import { useRouter } from 'vue-router';
import { Locale, Routes } from '@/shared/types/generic';
import i18n from '@/plugins/i18n/i18n';
import useErrorStore from '@/store/error/useErrorStore';
import useKudosStore from '@/store/kudos/useKudosStore';
import useProfileStore from '@/store/profile/useProfileStore';
import useSharedStore from '@/store/shared/useSharedStore';
import useStoreStore from '@/store/store/useStoreStore';
import useSurveysStore from '@/store/surveys/useSurveysStore';
import useTranslationsStore from '@/store/translations/useTranslationsStore';
import useDashboardStore from '@/store/dashboard/useDashboardStore';
import useFilesStore from '@/store/files/useFilesStore';
import useGamesStore from '@/store/games/useGamesStore';
import useImagesStore from '@/store/images/useImagesStore';
import useLeaderboardStore from '@/store/leaderboard/useLeaderboardStore';
import useMetricsStore from '@/store/metrics/useMetricsStore';
import usePublicDisplaysStore from '@/store/publicDisplays/usePublicDisplaysStore';
import useSitesStore from '@/store/settings/useSitesStore';
import useStatusStore from '@/store/status/useStatusStore';
import useUserStore from '@/store/user/useUserStore';
import useFeatures from '../useFeatures/useFeatures';

export default function useLogout() {
  const router = useRouter();

  function resetStores(): void {
    useDashboardStore().$reset();
    useErrorStore().$reset();
    useFilesStore().$reset();
    useGamesStore().$reset();
    useImagesStore().$reset();
    useKudosStore().$reset();
    useLeaderboardStore().$reset();
    useMetricsStore().$reset();
    useProfileStore().$reset();
    usePublicDisplaysStore().$reset();
    useSitesStore().$reset();
    useSharedStore().$reset();
    useStatusStore().$reset();
    useStoreStore().$reset();
    useSurveysStore().$reset();
    useTokenStore().$reset();
    useTranslationsStore().$reset();
    useUserStore().$reset();
    useFeatures().$reset();
  }

  async function logout(): Promise<void> {
    resetStores();

    localStorage.clear();

    i18n.global.locale.value = process.env.VUE_APP_I18N_LOCALE || Locale.US;

    await router.push({ path: Routes.LOGIN, query: { bypass: 'true' } });
  }

  return {
    logout,
    resetStores,
  };
}
