import { defineStore } from 'pinia';
import { HttpErrorCodes } from '@/shared/types/generic';

interface ErrorState {
  errorCode?: HttpErrorCodes;
}

export default defineStore({
  id: 'error',

  state: (): ErrorState => ({
    errorCode: undefined,
  }),

  actions: {
    setErrorCode(errorCode?: HttpErrorCodes) {
      this.errorCode = errorCode;
    },
  },
});
