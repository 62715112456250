import { ApiResponse } from '@/shared/types/generic';
import qs from 'qs';
import {
  User,
  NuvoUserRow,
  EditUser,
  Operator,
  EditTeamRequest,
  Team,
  NewTeam,
  ChangeTeamRequest,
  CreateUserRequestBody,
  GetAllValidOperatorsResponse,
  ChangeSiteRequest,
  RequestUsersTimezones,
  UsersSites,
  LoadUsersRequest,
  LoadUsersResponse,
  UsersCount,
} from '@/shared/types/user';
import axios from '@/plugins/http/axios';
import { AxiosResponse } from 'axios';
import { GetRequestParameters } from '@/shared/types/requests';

export default {
  getUsers(request: LoadUsersRequest): Promise<ApiResponse<LoadUsersResponse>> {
    return axios.get('/users', {
      params: {
        page: request.page,
        size: request.size,
        teams: request.teams,
        roles: request.roles,
        query: request.query,
        order_by: request.sortBy ? request.orderBy : undefined,
        sort_by: request.sortBy,
        statuses: request.statuses,
        noTeams: request.noTeams,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  editUser(user: EditUser, userReference: string): Promise<void> {
    return axios.put(`/users/${userReference}`, {
      name: user.name,
      orgUserId: user.orgUserId || undefined,
      teamReference: user.teamReference,
      userStatusStaticReference: user.userStatusStaticReference,
      siteReference: user.siteReference,
    });
  },

  editTeam(team: EditTeamRequest, teamReference: string): Promise<void> {
    return axios.patch(`/teams/${teamReference}`, team);
  },

  validateUsers(users: NuvoUserRow[]): Promise<AxiosResponse> {
    return axios.post('/users/validation', users);
  },

  registerUsers(users: Partial<User>[]): Promise<{
    usersImported: number;
    usersWithErrors: number;
  }> {
    return axios.post('/users', users);
  },

  createTeam(team: NewTeam): Promise<void> {
    return axios.post('/teams', team);
  },

  deleteTeam(teamReference: string): Promise<void> {
    return axios.delete(`/teams/${teamReference}`);
  },

  changePassword(
    password: string,
    email: string,
    otp?: string,
  ): Promise<AxiosResponse> {
    const body = otp
      ? {
          email,
          password: window.btoa(password),
          otp,
        }
      : {
          email,
          password: window.btoa(password),
        };

    return axios.post('/users/change-password', body);
  },

  deleteUsers(userRef: string[]): Promise<{ message: string }> {
    return axios.delete('/users', { data: { userReferences: userRef } });
  },

  recoverPassword(email: string): Promise<AxiosResponse> {
    return axios.post('/users/recover-password', { email });
  },

  verifyCode(email: string, code: string): Promise<AxiosResponse> {
    return axios.post('/users/verify-otp', { email, otp: code });
  },

  getAllOperators(): Promise<ApiResponse<{ operators: Operator[] }>> {
    return axios.get('/users/operators');
  },

  reInvite(userRef: string): Promise<{ message: string }> {
    return axios.post(`/users/re-invite/${userRef}`);
  },

  getTeams({
    page,
    size,
    sortBy,
    orderBy,
    queryBy,
  }: GetRequestParameters = {}): Promise<ApiResponse<{ team: Team[] }>> {
    return axios.get('/teams', {
      params: {
        page,
        size,
        sort_by: sortBy,
        order_by: orderBy,
        query_by: queryBy,
      },
    });
  },

  getSimplifiedTeams(
    onlyTeamsWithOperators: boolean,
  ): Promise<ApiResponse<{ team: Team[] }>> {
    return axios.get('/teams/simplified', {
      params: {
        onlyTeamsWithOperators,
      },
    });
  },

  getCounters(): Promise<UsersCount> {
    return axios.get('/users/count');
  },

  changeTeam(request: ChangeTeamRequest): Promise<void> {
    return axios.patch('/users/team', request);
  },

  createUser(requestBody: CreateUserRequestBody): Promise<void> {
    return axios.post('/users/new', requestBody);
  },

  getOnGoingTeamChallenges(
    teamRef: string,
  ): Promise<{ ongoingChallengeNumber: number }> {
    return axios.get(`/teams/ongoing-challenges/${teamRef}`);
  },

  getOnGoingUserChallenges(
    userRefs: string[],
  ): Promise<{ numberUsersOngoingChallenges: number }> {
    return axios.post(`/users/ongoing-challenges`, {
      userReferences: userRefs,
    });
  },

  getOnGoingUserSurveys(
    userRefs: string[],
  ): Promise<{ numberUsersLiveSurveys: number }> {
    return axios.post(`/users/live-surveys`, {
      userReferences: userRefs,
    });
  },

  getAllValidOperators(
    metricTypeReference?: string,
  ): Promise<GetAllValidOperatorsResponse> {
    return axios.get('/users/operators', { params: { metricTypeReference } });
  },

  getOperators(): Promise<ApiResponse<{ users: Operator[] }>> {
    return axios.get('/users/operators/simplified');
  },

  async getUserQrCode(userReferece: string): Promise<ArrayBuffer> {
    return axios.get(`/users/qr-code/${userReferece}`, {
      headers: { 'content-type': 'application/pdf' },
      responseType: 'arraybuffer',
    });
  },

  changeSite(request: ChangeSiteRequest): Promise<void> {
    return axios.patch('/users/site', request);
  },

  getTimezoneUsersParticipants(
    request: RequestUsersTimezones,
  ): Promise<{ differentTimezones: boolean }> {
    return axios.post(`/timezones/participants/different-timezone`, {
      workerReferences: request.workerReferences,
      teamReferences: request.teamReferences,
      audience: request.audience,
    });
  },

  getResultsForecast(
    request: RequestUsersTimezones,
  ): Promise<{ date: number | undefined }> {
    return axios.post(`/timezones/participants/date-forecast`, {
      workerReferences: request.workerReferences,
      teamReferences: request.teamReferences,
      audience: request.audience,
      startDate: request.startDate,
      endDate: request.endDate,
    });
  },

  getUsersSites(filterBy?: string): Promise<ApiResponse<UsersSites[]>> {
    return axios.get(`/users/operators/sites`, {
      params: {
        query_by: filterBy,
      },
    });
  },

  deleteManager(): Promise<{ message: string }> {
    return axios.delete('/users/manager');
  },

  verifyPassword(password: string): Promise<AxiosResponse> {
    const body = {
      password: window.btoa(password),
    };
    return axios.post('/auth/verify-password', body);
  },
};
