import {
  ChallengeGoal,
  ChallengeGoalStaticReference,
} from '@/shared/types/games';
import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';

export const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export const mockChallengeGoal = (
  staticReference = ChallengeGoalStaticReference.MOST_POINTS_WIN,
): ChallengeGoal => ({
  staticReference,
});

export const getGameGoals = (): ApiResponse<{
  challengeGoals: ChallengeGoal[];
}> => ({
  metadata,
  results: {
    challengeGoals: Object.values(ChallengeGoalStaticReference)
      .filter(
        (staticReference) =>
          staticReference !== ChallengeGoalStaticReference.TARGET,
      )
      .map(mockChallengeGoal),
  },
});
