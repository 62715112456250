/* istanbul ignore file */

import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { StoreType } from '@/shared/types/store';
import useTokenStore from '@/store/token/useTokenStore';
import { isEmpty } from '@/shared/helpers/validators/validators';
import useProfileStore from '@/store/profile/useProfileStore';
import {
  Feature,
  RouteAuthenticationRequirement,
  Routes,
} from '@/shared/types/generic';
import useTranslationsStore from '@/store/translations/useTranslationsStore';
import {
  GamesWizardStep,
  ChallengeTypeStaticReference,
} from '@/shared/types/games';
import { SurveyWizardStep } from '@/shared/types/surveys';
import useFeatures from '@/composables/useFeatures/useFeatures';

const { isFeatureEnabled, loadFeatures } = useFeatures();

function preventDirectRouteChange(
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  destination = '/',
) {
  if (from.name || to.query.bypass === 'true') {
    next();
  } else {
    next(destination);
  }
}

function preventRouteChange(
  from: RouteLocationNormalized,
  filteredPath: string,
  toPath: string,
  next: NavigationGuardNext,
) {
  if (from.fullPath.includes(filteredPath)) {
    next();
  } else {
    next(toPath);
  }
}

const Users = () =>
  import(/* webpackChunkName: "users" */ '@/views/UsersView/UsersView.vue');
const UsersTable = () =>
  import(
    /* webpackChunkName: "users" */ '@/components/user/UsersTable/UsersTable.vue'
  );
const TeamsList = () =>
  import(
    /* webpackChunkName: "users" */ '@/components/user/TeamsList/TeamsList.vue'
  );
const Leaderboard = () =>
  import(
    /* webpackChunkName: "leaderboard" */ '@/views/LeaderboardView/LeaderboardView.vue'
  );
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/DashboardView/DashboardView.vue'
  );
const Games = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/views/GamesView/GamesView.vue'
  );
const GamesWizard = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/components/games/GamesWizard/GamesWizard.vue'
  );
const GamesStepAudienceView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepAudienceView/GamesStepAudienceView.vue'
  );
const GamesStepModeView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepModeView/GamesStepModeView.vue'
  );
const GamesStepTypeView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepTypeView/GamesStepTypeView.vue'
  );
const GamesStepSummaryView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepSummaryView/GamesStepSummaryView.vue'
  );
const GamesStepDuration = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepDurationView/GamesStepDurationView.vue'
  );
const GamesStepFastestGoalView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepFastestGoalView/GamesStepFastestGoalView.vue'
  );
const GamesStepClassicGoalView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepClassicGoalView/GamesStepClassicGoalView.vue'
  );
const GamesStepPrizeView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepPrizeView/GamesStepPrizeView.vue'
  );
const GamesStepAchieverPrizeView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepAchieverPrizeView/GamesStepAchieverPrizeView.vue'
  );
const GamesStepAchieverGoalView = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/views/GamesStepAchieverGoalView/GamesStepAchieverGoalView.vue'
  );
const GameSidebarCalculating = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarCalculating/GameSidebarCalculating.vue'
  );
const GameSidebarOnGoing = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarOnGoing/GameSidebarOnGoing.vue'
  );
const GameSidebarFinished = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarFinished/GameSidebarFinished.vue'
  );
const GameSidebarViewBase = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarViewBase/GameSidebarViewBase.vue'
  );
const GameSidebarFinishedClassic = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarFinished/GameSidebarFinishedClassic/GameSidebarFinishedClassic.vue'
  );
const GameSidebarFinishedFastest = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarFinished/GameSidebarFinishedFastest/GameSidebarFinishedFastest.vue'
  );
const GameSidebarFinishedReachTeam = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarFinished/GameSidebarFinishedReachTeam/GameSidebarFinishedReachTeam.vue'
  );
const GameSidebarFinishedAchiever = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarFinished/GameSidebarFinishedAchiever/GameSidebarFinishedAchiever.vue'
  );
const GameSidebarEdit = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarEdit/GameSidebarEdit.vue'
  );
const GameSidebarEditFastest = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarEdit/GameSidebarEditFastest/GameSidebarEditFastest.vue'
  );
const GameSidebarEditClassic = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarEdit/GameSidebarEditClassic/GameSidebarEditClassic.vue'
  );
const GameSidebarEditAchiever = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/GameSidebar/GameSidebarEdit/GameSidebarEditAchiever/GameSidebarEditAchiever.vue'
  );

const ProfileView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileView/ProfileView.vue'
  );

const EditProdileView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/EditProfileView/EditProfileView.vue'
  );

const SecurityView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/SecurityView/SecurityView.vue'
  );

const SecurityDetailsView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/SecurityDetailsView/SecurityDetailsView.vue'
  );

const AccountSettingsView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/AccountSettingsView/AccountSettingsView.vue'
  );

const DeleteAccountView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/DeleteAccountView/DeleteAccountView.vue'
  );

const AccountSettingsChangePasswordView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/AccountSettingsChangePasswordView/AccountSettingsChangePassword.vue'
  );

const SettingsView = () =>
  import(
    /* webpackChunkName: "settings" */ '@/views/SettingsView/SettingsView.vue'
  );

const MetricsView = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/views/MetricsView/MetricsView.vue'
  );

const SitesList = () =>
  import(
    /* webpackChunkName: "settings" */ '@/components/settings/sites/SitesList/SitesList.vue'
  );

const MetricsTable = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/components/metrics/MetricsTable/MetricsTable.vue'
  );
const CategoriesList = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/components/metrics/CategoriesList/CategoriesList.vue'
  );
const MetricsOverview = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/views/MetricsOverview/MetricsOverview.vue'
  );
const AddMetric = () =>
  import(
    /* webpackChunkName: "add-metrics" */ '@/views/AddMetricView/AddMetricView.vue'
  );
const Store = () =>
  import(/* webpackChunkName: "store" */ '@/views/StoreView/StoreView.vue');
const AddStoreItem = () =>
  import(
    /* webpackChunkName: "store" */ '@/views/AddStoreItemsView/AddStoreItemsView.vue'
  );
const DataInput = () =>
  import(
    /* webpackChunkName: "data-input" */ '@/views/DataInputView/DataInputView.vue'
  );
const AddDataInputView = () =>
  import(
    /* webpackChunkName: "data-input" */ '@/views/AddDataInputView/AddDataInputView.vue'
  );
const NoConnectionView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/NoConnectionView/NoConnectionView.vue'
  );
const MaintenanceView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/MaintenanceView/MaintenanceView.vue'
  );
const LoginView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/LoginView/LoginView.vue'
  );
const ForgotPasswordView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/ForgotPasswordView/ForgotPasswordView.vue'
  );
const ChangePasswordView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/ChangePasswordView/ChangePasswordView.vue'
  );
const FarewellView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/FarewellView/FarewellView.vue'
  );
const AddUser = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/views/AddUserView/AddUserView.vue'
  );
const PublicDisplaysView = () =>
  import(
    /* webpackChunkName: "public-displays" */ '@/views/PublicDisplaysView/PublicDisplaysView.vue'
  );
const SurveysView = () =>
  import(
    /* webpackChunkName: "surveys" */ '@/views/SurveysView/SurveysView.vue'
  );
const SurveysTable = () =>
  import(
    /* webpackChunkName: "surveys" */ '@/components/surveys/SurveysTable/SurveysTable.vue'
  );
const SurveysWizard = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysWizard/SurveysWizard.vue'
  );
const SurveysStepId = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepId/SurveysStepId.vue'
  );
const SurveysStepDuration = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepDuration/SurveysStepDuration.vue'
  );
const SurveysStepQuestions = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepQuestions/SurveysStepQuestions.vue'
  );
const SurveysStepSummary = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepSummary/SurveysStepSummary.vue'
  );
const IconsView = () =>
  import(/* webpackChunkName: "icons" */ '@/views/IconsView/IconsView.vue');

const ChallengeWizard = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/components/games/ChallengeWizard/ChallengeWizardView/ChallengeWizardView.vue'
  );

const ChallengesTableSwapper = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/games/ChallengesTableSwapper/ChallengesTableSwapper.vue'
  );

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'main',
    redirect: Routes.HOME,
  },
  {
    path: Routes.FIRST_LOGIN,
    name: 'first-login',
    redirect: Routes.GAMES,
    beforeEnter: () => {
      const tokenStore = useTokenStore();

      tokenStore.setToken('', '', '');
    },
  },
  {
    path: Routes.ICONS,
    name: 'icons',
    component: IconsView,
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV !== 'development') {
        next({ path: '/' });
      }
      next();
    },
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: Routes.LOGIN,
    name: 'login',
    component: LoginView,
  },
  {
    path: Routes.FAREWELL,
    name: 'farewell',
    component: FarewellView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
    },
  },

  {
    path: Routes.FORGOT_PASSWORD,
    name: 'forgot-password',
    component: ForgotPasswordView,
  },
  {
    path: Routes.CHANGE_PASSWORD,
    name: 'change-password',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
    },
    component: ChangePasswordView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
  {
    path: Routes.HOME,
    name: 'home',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (!isFeatureEnabled(Feature.DASHBOARD_MANAGER)) {
        next({ path: Routes.GAMES });
      }
      next();
    },
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: Routes.USERS,
    name: 'users',
    component: Users,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        name: 'usersTable',
        component: UsersTable,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.USERS_TEAMS,
        name: 'teams-list',
        component: TeamsList,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.USERS_ADD,
        name: 'addUser',
        component: AddUser,
        meta: {
          miniMode: true,
        },
      },
    ],
  },
  {
    path: Routes.LEADERBOARD,
    name: 'leaderboard',
    component: Leaderboard,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: Routes.METRICS,
    name: 'metrics',
    redirect: Routes.METRICS_OVERVIEW,
    component: MetricsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: Routes.METRICS_TABLE,
        name: 'metrics-table',
        component: MetricsTable,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.METRICS_OVERVIEW,
        name: 'metrics-overview',
        component: MetricsOverview,
        beforeEnter: (to, from, next) => {
          if (!isFeatureEnabled(Feature.METRIC_OVERVIEW_VISIBILITY)) {
            next({ path: Routes.METRICS_TABLE });
          } else {
            next();
          }
        },
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.METRICS_CATEGORIES,
        name: 'categories-list',
        component: CategoriesList,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.METRICS_ADD,
        name: 'addMetric',
        component: AddMetric,
        meta: {
          miniMode: true,
          authenticationRequirement:
            RouteAuthenticationRequirement.AUTHENTICATED,
        },
      },
    ],
  },

  {
    path: Routes.ACCOUNT_SETTINGS,
    name: 'accountSettings',
    component: AccountSettingsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },

    children: [
      {
        path: '',
        name: 'profile',
        component: ProfileView,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.ACCOUNT_SETTINGS_EDIT_PROFILE,
        name: 'account-settings',
        component: EditProdileView,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.ACCOUNT_SETTINGS_SECURITY,
        name: 'security',
        component: SecurityView,
        meta: {
          firstLevel: true,
        },
        beforeEnter: (to, from, next) => {
          if (!isFeatureEnabled(Feature.MANAGER_SETTINGS)) {
            next({ path: Routes.ACCOUNT_SETTINGS });
          }
          next();
        },
        children: [
          {
            path: '',
            name: 'securityDetails',
            component: SecurityDetailsView,
            meta: {
              firstLevel: true,
            },
          },
          {
            path: Routes.ACCOUNT_SETTINGS_SECURITY_DELETE_ACCOUNT,
            name: 'deleteAccount',
            component: DeleteAccountView,
            meta: {
              firstLevel: true,
            },
          },
          {
            path: Routes.ACCOUNT_SETTINGS_SECURITY_CHANGE_PASSWORD,
            name: 'changePassword',
            beforeEnter: (to, from, next) =>
              preventDirectRouteChange(from, to, next),
            component: AccountSettingsChangePasswordView,
            meta: {
              firstLevel: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: Routes.SETTINGS,
    name: 'settings',
    redirect: Routes.SETTINGS_SITES,
    component: SettingsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: Routes.SETTINGS_SITES,
        name: 'sites-list',
        component: SitesList,
        meta: {
          firstLevel: true,
        },
      },
    ],
  },
  {
    path: Routes.GAMES,
    name: 'games',
    component: Games,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        name: 'gamesTable',
        component: ChallengesTableSwapper,
        meta: {
          firstLevel: true,
        },
        children: [
          {
            path: Routes.GAMES_SIDEBAR,
            beforeEnter: (to, from, next) =>
              preventDirectRouteChange(from, to, next, Routes.GAMES),
            children: [
              {
                path: Routes.GAMES_SIDEBAR_SCHEDULED,
                component: GameSidebarViewBase,
              },
              {
                path: Routes.GAMES_SIDEBAR_ONGOING,
                component: GameSidebarOnGoing,
              },
              {
                path: Routes.GAMES_SIDEBAR_CALCULATING,
                component: GameSidebarCalculating,
              },
              {
                path: Routes.GAMES_SIDEBAR_FINISHED,
                component: GameSidebarFinished,
                children: [
                  {
                    path: ChallengeTypeStaticReference.CLASSIC,
                    redirect: Routes.GAMES_SIDEBAR_FINISHED_CLASSIC,
                  },
                  {
                    path: ChallengeTypeStaticReference.FASTEST,
                    redirect: Routes.GAMES_SIDEBAR_FINISHED_FASTEST,
                  },
                  {
                    path: ChallengeTypeStaticReference.REACHED_AS_TEAM,
                    redirect: Routes.GAMES_SIDEBAR_FINISHED_REACH_TARGET,
                  },
                  {
                    path: ChallengeTypeStaticReference.ACHIEVER,
                    redirect: Routes.GAMES_SIDEBAR_FINISHED_ACHIEVER,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_FINISHED_CLASSIC,
                    component: GameSidebarFinishedClassic,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_FINISHED_FASTEST,
                    component: GameSidebarFinishedFastest,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_FINISHED_REACH_TARGET,
                    component: GameSidebarFinishedReachTeam,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_FINISHED_ACHIEVER,
                    component: GameSidebarFinishedAchiever,
                  },
                ],
              },
              {
                path: Routes.GAMES_SIDEBAR_CANCELED,
                component: GameSidebarViewBase,
              },
              {
                path: Routes.GAMES_SIDEBAR_EDIT,
                component: GameSidebarEdit,
                children: [
                  {
                    path: ChallengeTypeStaticReference.CLASSIC,
                    redirect: Routes.GAMES_SIDEBAR_EDIT_CLASSIC,
                  },
                  {
                    path: ChallengeTypeStaticReference.FASTEST,
                    redirect: Routes.GAMES_SIDEBAR_EDIT_FASTEST,
                  },
                  {
                    path: ChallengeTypeStaticReference.REACHED_AS_TEAM,
                    redirect: Routes.GAMES_SIDEBAR_EDIT_REACH_TARGET,
                  },
                  {
                    path: ChallengeTypeStaticReference.ACHIEVER,
                    redirect: Routes.GAMES_SIDEBAR_EDIT_ACHIEVER,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_EDIT_FASTEST,
                    component: GameSidebarEditFastest,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_EDIT_REACH_TARGET,
                    component: GameSidebarEditFastest,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_EDIT_CLASSIC,
                    component: GameSidebarEditClassic,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_EDIT_ACHIEVER,
                    component: GameSidebarEditAchiever,
                  },
                ],
              },
              {
                path: Routes.GAMES_SIDEBAR_DUPLICATE,
                component: GameSidebarEdit,
                children: [
                  {
                    path: ChallengeTypeStaticReference.CLASSIC,
                    redirect: Routes.GAMES_SIDEBAR_DUPLICATE_CLASSIC,
                  },
                  {
                    path: ChallengeTypeStaticReference.FASTEST,
                    redirect: Routes.GAMES_SIDEBAR_DUPLICATE_FASTEST,
                  },
                  {
                    path: ChallengeTypeStaticReference.REACHED_AS_TEAM,
                    redirect: Routes.GAMES_SIDEBAR_DUPLICATE_REACH_TARGET,
                  },
                  {
                    path: ChallengeTypeStaticReference.ACHIEVER,
                    redirect: Routes.GAMES_SIDEBAR_DUPLICATE_ACHIEVER,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_DUPLICATE_FASTEST,
                    component: GameSidebarEditFastest,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_DUPLICATE_REACH_TARGET,
                    component: GameSidebarEditFastest,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_DUPLICATE_CLASSIC,
                    component: GameSidebarEditClassic,
                  },
                  {
                    path: Routes.GAMES_SIDEBAR_DUPLICATE_ACHIEVER,
                    component: GameSidebarEditAchiever,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: Routes.GAMES_ADD_CHALLENGE,
        name: 'addChallenge',
        component: ChallengeWizard,
        meta: {
          miniMode: true,
        },

        beforeEnter: (to, from, next) => {
          if (!isFeatureEnabled(Feature.NEW_CHALLENGE_WIZARD)) {
            preventDirectRouteChange(from, to, next);
            return;
          }

          next();
        },
      },

      {
        path: Routes.GAMES_ADD,
        name: 'addGames',
        component: GamesWizard,
        beforeEnter: (to, from, next) => {
          if (isFeatureEnabled(Feature.NEW_CHALLENGE_WIZARD)) {
            next({ path: Routes.GAMES_ADD_CHALLENGE });
          }
          next();
        },
        redirect: Routes.GAMES_ADD_AUDIENCE,
        meta: {
          miniMode: true,
        },
        children: [
          {
            path: Routes.GAMES_ADD_AUDIENCE,
            component: GamesStepAudienceView,
            meta: {
              stepTitle: 'games.wizard.titles.audience',
              step: GamesWizardStep.AUDIENCE,
            },
          },
          {
            path: Routes.GAMES_ADD_MODE,
            component: GamesStepModeView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.mode',
              step: GamesWizardStep.MODE,
            },
          },
          {
            path: Routes.GAMES_ADD_TYPE,
            component: GamesStepTypeView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.type',
              step: GamesWizardStep.TYPE,
            },
          },
          {
            path: `goal/${ChallengeTypeStaticReference.CLASSIC}`,
            redirect: Routes.GAMES_ADD_GOAL_CLASSIC,
          },
          {
            path: `goal/${ChallengeTypeStaticReference.FASTEST}`,
            redirect: Routes.GAMES_ADD_GOAL_FASTEST,
          },
          {
            path: `goal/${ChallengeTypeStaticReference.REACHED_AS_TEAM}`,
            redirect: Routes.GAMES_ADD_GOAL_REACH_TARGET,
          },
          {
            path: `goal/${ChallengeTypeStaticReference.ACHIEVER}`,
            redirect: Routes.GAMES_ADD_GOAL_ACHIEVER,
          },
          {
            path: Routes.GAMES_ADD_GOAL_FASTEST,
            component: GamesStepFastestGoalView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.goal',
              step: GamesWizardStep.GOAL,
            },
          },
          {
            path: Routes.GAMES_ADD_GOAL_REACH_TARGET,
            component: GamesStepFastestGoalView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.goal',
              step: GamesWizardStep.GOAL,
            },
          },
          {
            path: Routes.GAMES_ADD_GOAL_CLASSIC,
            component: GamesStepClassicGoalView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.goal',
              step: GamesWizardStep.GOAL,
            },
          },
          {
            path: Routes.GAMES_ADD_GOAL_ACHIEVER,
            component: GamesStepAchieverGoalView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.goal',
              step: GamesWizardStep.GOAL,
            },
          },
          {
            path: Routes.GAMES_ADD_DURATION,
            component: GamesStepDuration,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.duration',
              step: GamesWizardStep.DURATION,
            },
          },
          {
            path: `${Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC}/${ChallengeTypeStaticReference.ACHIEVER}`,
            redirect: Routes.GAMES_ADD_PRIZE_DISTRIBUTION_ACHIEVER,
          },
          {
            path: `${Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC}/${ChallengeTypeStaticReference.CLASSIC}`,
            redirect: Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC,
          },
          {
            path: `${Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC}/${ChallengeTypeStaticReference.FASTEST}`,
            redirect: Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC,
          },
          {
            path: `${Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC}/${ChallengeTypeStaticReference.REACHED_AS_TEAM}`,
            redirect: Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC,
          },
          {
            path: Routes.GAMES_ADD_PRIZE_DISTRIBUTION_ACHIEVER,
            component: GamesStepAchieverPrizeView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.achieverPrizeDistribution',
              step: GamesWizardStep.PRIZE,
            },
          },
          {
            path: Routes.GAMES_ADD_PRIZE_DISTRIBUTION_GENERIC,
            component: GamesStepPrizeView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.prizeDistribution',
              step: GamesWizardStep.PRIZE,
            },
          },
          {
            path: Routes.GAMES_ADD_SUMMARY,
            component: GamesStepSummaryView,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.GAMES_ADD,
                Routes.GAMES_ADD_AUDIENCE,
                next,
              ),
            meta: {
              stepTitle: 'games.wizard.titles.summary',
              step: GamesWizardStep.SUMMARY,
            },
          },
        ],
      },
    ],
  },
  {
    path: Routes.STORE,
    name: 'store',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        alias: 'prizes',
        name: 'prizes',
        component: Store,
        meta: {
          firstLevel: true,
          storeType: StoreType.Prize as StoreType,
        },
      },
      {
        path: Routes.STORE_COLLECTABLES,
        name: 'collectables',
        component: Store,
        meta: {
          firstLevel: true,
          storeType: StoreType.Collectable as StoreType,
        },
      },
      {
        path: Routes.STORE_PURCHASE_ACTIVTY,
        name: 'purchase-activity',
        component: Store,
        meta: {
          firstLevel: true,
          storeType: StoreType.PurchaseActivity as StoreType,
        },
      },
      {
        path: Routes.STORE_ADD,
        name: 'addStoreItem',
        component: AddStoreItem,
        meta: {
          miniMode: true,
        },
      },
    ],
  },
  {
    path: Routes.DATA_IMPORT,
    name: 'dataInput',
    component: DataInput,
    beforeEnter: (to, from, next) => {
      if (!isFeatureEnabled(Feature.METRIC_DATA_IMPORT)) {
        preventDirectRouteChange(from, to, next);
        return;
      }

      next();
    },
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: Routes.DATA_IMPORT_ADD,
        component: AddDataInputView,
      },
    ],
  },
  {
    path: Routes.PUBLIC_DISPLAYS,
    name: 'publicDisplays',
    component: PublicDisplaysView,
    beforeEnter: (to, from, next) => {
      if (!isFeatureEnabled(Feature.PUBLIC_DISPLAY_MANAGER)) {
        preventDirectRouteChange(from, to, next);
        return;
      }

      next();
    },
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: Routes.SURVEYS,
    name: 'surveys',
    component: SurveysView,
    beforeEnter: (to, from, next) => {
      if (
        !isFeatureEnabled(Feature.SURVEY_CUSTOM_QUESTIONS) &&
        !isFeatureEnabled(Feature.SURVEY_DEFAULT_QUESTIONS)
      ) {
        preventDirectRouteChange(from, to, next);
        return;
      }

      next();
    },
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        name: 'surveysTable',
        component: SurveysTable,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.SURVEYS_ADD,
        name: 'addSurveys',
        component: SurveysWizard,
        redirect: Routes.SURVEYS_ADD_ID,
        meta: {
          miniMode: true,
        },
        children: [
          {
            path: Routes.SURVEYS_ADD_ID,
            component: SurveysStepId,
            meta: {
              stepTitle: 'surveys.wizard.steps.id.title',
              step: SurveyWizardStep.ID,
            },
          },
          {
            path: Routes.SURVEYS_ADD_DURATION,
            component: SurveysStepDuration,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.SURVEYS_ADD,
                Routes.SURVEYS_ADD_ID,
                next,
              ),
            meta: {
              stepTitle: 'surveys.wizard.steps.duration.title',
              step: SurveyWizardStep.DURATION,
            },
          },
          {
            path: Routes.SURVEYS_ADD_QUESTIONS,
            component: SurveysStepQuestions,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.SURVEYS_ADD,
                Routes.SURVEYS_ADD_ID,
                next,
              ),
            meta: {
              stepTitle: 'surveys.wizard.steps.questions.title',
              step: SurveyWizardStep.QUESTIONS,
            },
          },
          {
            path: Routes.SURVEYS_ADD_SUMMARY,
            component: SurveysStepSummary,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.SURVEYS_ADD,
                Routes.SURVEYS_ADD_ID,
                next,
              ),
            meta: {
              stepTitle: 'surveys.wizard.steps.summary.title',
              step: SurveyWizardStep.SUMMARY,
            },
          },
        ],
      },
    ],
  },
  {
    path: Routes.ERROR_502,
    name: 'no-connection',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: NoConnectionView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
  {
    path: Routes.ERROR_503,
    name: 'maintenance',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: MaintenanceView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const tokenStore = useTokenStore();

  const profileStore = useProfileStore();
  const translationsStore = useTranslationsStore();

  const routeRequiresAuthentication = to.matched.some(
    (route) =>
      route.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );

  const routeHasUniversalAccess =
    to.meta.authenticationRequirement ===
    RouteAuthenticationRequirement.UNIVERSAL;

  let isAuthenticated = !isEmpty(tokenStore.token);

  const profileIsNotLoaded = profileStore.userProfile === undefined;

  if (!(to.name === 'maintenance' || to.name === 'no-connection')) {
    if (isAuthenticated && profileIsNotLoaded) {
      try {
        await loadFeatures();

        const promises: Promise<void>[] = [
          profileStore.loadProfile(),
          profileStore.loadProfileSite(),
        ];

        if (process.env.NODE_ENV !== 'development') {
          promises.push(translationsStore.loadTranslations());
        }

        await Promise.all(promises);
      } catch (e) {
        profileStore.userProfile = undefined;
      }

      isAuthenticated = !isEmpty(tokenStore.token);
    }

    if (
      !routeRequiresAuthentication &&
      !routeHasUniversalAccess &&
      isAuthenticated
    ) {
      next({ path: '/', query: { bypass: 'true' } });

      return;
    }

    if (
      routeRequiresAuthentication &&
      !isAuthenticated &&
      !routeHasUniversalAccess
    ) {
      next({ path: Routes.LOGIN, query: { bypass: 'true' } });

      return;
    }
  }

  next();
});

router.afterEach((to) => {
  if (to.name === 'change-password') {
    const historyObject = { page: 'change-password' };
    window.history.pushState(historyObject, '', to.path);
  }
  if (to.query?.bypass === 'true') {
    router.replace({ ...to, query: undefined });
  }
});

export default router;
