import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FirstTimeExperience = _resolveComponent("FirstTimeExperience")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_AppGlobalConfirmationModal = _resolveComponent("AppGlobalConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.requiresAuthentication)
      ? (_openBlock(), _createBlock(_component_AppLayout, { key: 0 }, {
          default: _withCtx(() => [
            (_ctx.showFTXModals)
              ? (_openBlock(), _createBlock(_component_FirstTimeExperience, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
    _createVNode(_component_AppLoader, {
      state: _ctx.sharedStore.loaderState,
      target: _ctx.sharedStore.loaderTarget,
      transparent: _ctx.sharedStore.loaderTransparent,
      text: _ctx.sharedStore.loaderText
    }, null, 8, ["state", "target", "transparent", "text"]),
    _createVNode(_component_AppGlobalConfirmationModal)
  ], 64))
}