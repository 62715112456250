import {
  ChallengeDuelsResults,
  GameLeaderboardResponse,
} from '@/shared/types/games';
import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';

const metadata: ResponseMetadata = {
  totalItems: 3,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export const getGameLeaderboard = (
  limit = 7,
): ApiResponse<GameLeaderboardResponse> => {
  const leaderboard = [
    {
      position: 1,
      name: 'Anna Banana',
      username: 'Anna Banana',
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 1250,
      points: 3109456,
      coins: 10,
      result: ChallengeDuelsResults.WIN,
    },
    {
      position: 2,
      name: 'Celestina',
      username: 'Celestina',
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 1112,
      points: 210945,
      coins: 0,
      result: ChallengeDuelsResults.LOSE,
    },
    {
      position: 3,
      name: 'Manel El',
      username: 'Manel El',
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 950,
      points: 11094,
      coins: 0,
      result: ChallengeDuelsResults.LOSE,
    },
    {
      position: 4,
      name: 'John Doe',
      username: 'John Doe',
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 800,
      points: 11094,
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      coins: 0,
      result: ChallengeDuelsResults.LOSE,
    },
    {
      position: 5,
      name: 'Rainych',
      username: 'Rainych',
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 780,
      points: 11094,
      coins: 0,
      result: ChallengeDuelsResults.LOSE,
    },
    {
      position: 6,
      name: 'Ravi Jones',
      username: 'Ravi Jones',
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 700,
      points: 11094,
      coins: 0,
      result: ChallengeDuelsResults.LOSE,
    },
    {
      position: 7,
      name: 'Monica Geller',
      username: 'Monica Geller',
      incomingUnits: 1000,
      incomingCoins: 1000,
      incomingPoints: 1000,
      urlAvatar:
        'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
      units: 650,
      points: 11094,
      coins: 0,
      result: ChallengeDuelsResults.LOSE,
    },
  ];
  return {
    metadata,
    results: {
      userLeaderboards: leaderboard.slice(0, limit),
      lastUpdateDate: 10000000,
      nextClaimCycleDate: 10200000,
    },
  };
};

export const getGameLeaderboardDuels =
  (): ApiResponse<GameLeaderboardResponse> => {
    const leaderboardDuels = [
      {
        position: 2,
        name: 'Team 1',
        username: 'Team 1',
        urlAvatar:
          'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
        units: 1250,
        points: 3109456,
        incomingUnits: 1000,
        incomingCoins: 1000,
        incomingPoints: 1000,
        coins: 10,
        result: ChallengeDuelsResults.TIE,
      },
      {
        position: 1,
        name: 'Team 2',
        username: 'Team 2',
        incomingUnits: 1000,
        incomingCoins: 1000,
        incomingPoints: 1000,
        urlAvatar:
          'https://api.develop.engageworkers.com/v2/storages/container/org1/image/Avatar_default.png',
        units: 1112,
        points: 210945,
        coins: 0,
        result: ChallengeDuelsResults.TIE,
      },
    ];
    return {
      metadata,
      results: {
        userLeaderboards: leaderboardDuels,
        lastUpdateDate: new Date('2022-11-09T12:33').getTime(),
        nextClaimCycleDate: new Date('2022-11-14T12:33').getTime(),
      },
    };
  };
