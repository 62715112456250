import axios from '@/plugins/http/axios';
import { PinInformation } from '@/shared/types/publicDisplays';

export default {
  getPinInformation(): Promise<PinInformation> {
    return axios.get('/management/public-displays');
  },

  editPin(pin: string): Promise<{ message: string }> {
    return axios.post('/management/public-displays/edit/pin', { pin });
  },

  getRandomPinInformation(): Promise<PinInformation> {
    return axios.get('/management/public-displays/random/pin');
  },

  togglePinPublicDisplays(activePin: boolean): Promise<void> {
    return axios.put('/management/public-displays/active-pin', { activePin });
  },
};
