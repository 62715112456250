import { defineStore } from 'pinia';
import {
  NewGameRequest,
  Game,
  EditGameRequest,
  PositionGamesDistribution,
  SchedulerChallengeConfig,
  ChallengeMode,
  ChallengeModeStaticReference,
  ChallengeType,
  ChallengeGoal,
  ChallengeAudience,
  GameStatus,
  GameStatusId,
  ChallengeTypeStaticReference,
  ChallengeGoalStaticReference,
  RecurrencyOption,
  GameLeaderboardResponse,
  GameDetails,
  LoadGameRequest,
  ChallengeAudienceStaticReference,
  CreateChallengeEnabled,
  GamesParticipantsDetail,
  GamesParticipantsTeamsDetail,
  Participants,
  GameLeaderboardUser,
} from '@/shared/types/games';
import {
  ChallengeFormatOptions,
  ChallengeForm,
  ChallengeFormat,
  ChallengeModeType,
  ChallengeImage,
  IChallenge,
} from '@/shared/types/wizard';
import gamesService from '@/shared/services/gamesService/gamesService';
import { TableRow } from '@/shared/types/components';
import { ApiResponse, ProcessCompletionDetails } from '@/shared/types/generic';
import { ResponseError } from '@/shared/types/utils';
import { GetRequestParameters } from '@/shared/types/requests';

interface Format {
  mode: ChallengeModeStaticReference;
  type: ChallengeTypeStaticReference;
  audience: ChallengeAudienceStaticReference;
}

interface ChallengeModeTypeReference extends ChallengeModeType {
  audience: ChallengeAudienceStaticReference;
}

export default defineStore({
  id: 'games',

  state: () => ({
    challengeSchedulerConfig: [] as SchedulerChallengeConfig[],
    loadGameRequest: {} as LoadGameRequest,
    challengeCount: {
      numberOfChallenges: 0,
      numberOfFilteredChallenges: 0,
      numberOfFinished: 0,
      numberOfOnGoing: 0,
      numberOfScheduled: 0,
      numberOfCurrent: 0,
      numberOfClosed: 0,
    },
    games: [] as TableRow<Game>[],
    gameModes: [] as ChallengeMode[],
    gameTypes: [] as ChallengeType[],
    gameLeaderboard: {} as ApiResponse<GameLeaderboardResponse>,
    distributionPoints: {} as PositionGamesDistribution,
    distributionCoins: {} as PositionGamesDistribution,
    gameGoals: [] as ChallengeGoal[],
    duplicatedGame: {} as Game,
    gameAudience: [] as ChallengeAudience[],
    recurrencyConfigs: [] as RecurrencyOption[],
    recurrentPointsMultiplier: 0,
    modeFilter: [] as ChallengeModeStaticReference[],
    typeFilter: [] as ChallengeTypeStaticReference[],
    challengeFormats: [] as ChallengeFormatOptions[],
    challengeWizardForm: {} as ChallengeForm,
    audienceReferences: {} as Record<string, string>,
    formatTranslation: {} as Record<ChallengeFormat, string[]>,
    challengeImage: [] as ChallengeImage[],
    wizardFormError: 0,
    isEnabledCreateChallenge: {} as CreateChallengeEnabled,
    challenges: [] as TableRow<IChallenge>[],
    participantsDetail: {} as GamesParticipantsDetail,
    teamsParticipantsPaginated: [] as GamesParticipantsTeamsDetail[],
    participantsPaginated: [] as Participants[],
    gameLeaderboardPaginated: [] as GameLeaderboardUser[],
    duplicatedChallenge: {} as IChallenge,
  }),

  getters: {
    getFormatCorrespondenceModeType(state) {
      const formatsByAudience = state.challengeFormats.reduce(
        (
          acc: ChallengeModeTypeReference[],
          audience: ChallengeFormatOptions,
        ) => {
          acc.push(
            ...Object.values(
              audience.challengeModeTypes.map((modeType: ChallengeModeType) => {
                return {
                  ...modeType,
                  audience: audience.audience
                    .staticReference as ChallengeAudienceStaticReference,
                };
              }),
            ),
          );
          return acc;
        },
        [] as ChallengeModeTypeReference[],
      );

      return formatsByAudience.reduce(
        (
          acc: Record<ChallengeFormat, Format>,
          format: ChallengeModeTypeReference,
        ) => {
          acc[format.staticReference as ChallengeFormat] = {
            mode: format.challengeMode.staticReference,
            type: format.challengeType.staticReference,
            audience: format.audience,
          };
          return acc;
        },
        {} as Record<ChallengeFormat, Format>,
      );
    },
  },

  actions: {
    getStatusByStatusId(statusId: GameStatusId): GameStatus[] {
      switch (statusId) {
        case GameStatusId.SCHEDULED:
          return [GameStatus.NOT_STARTED, GameStatus.READY];
        case GameStatusId.ON_GOING:
          return [GameStatus.ON_GOING];
        case GameStatusId.FINISHED:
          return [
            GameStatus.CALCULATING_RESULTS,
            GameStatus.COMPLETED,
            GameStatus.DONE,
            GameStatus.CANCELED,
          ];
        default:
          return Object.values(GameStatus);
      }
    },

    getChallengeStatusByStatusId(statusId: GameStatusId): GameStatus[] {
      switch (statusId) {
        case GameStatusId.SCHEDULED:
          return [GameStatus.NOT_STARTED, GameStatus.READY];
        case GameStatusId.ON_GOING:
          return [GameStatus.ON_GOING, GameStatus.CALCULATING_RESULTS];
        case GameStatusId.FINISHED:
          return [GameStatus.COMPLETED, GameStatus.DONE, GameStatus.CANCELED];
        default:
          return Object.values(GameStatus);
      }
    },

    setLoadGameRequest(request: LoadGameRequest): void {
      this.loadGameRequest = request;
    },

    resetGames(): void {
      this.games = [];
      this.challenges = [];
    },

    async loadGames(): Promise<void> {
      try {
        const response = await gamesService.getGames(this.loadGameRequest);
        this.games = response.results.challenges;
        this.challengeCount = {
          numberOfChallenges: response.results.numberOfChallenges,
          numberOfFilteredChallenges:
            response.results.numberOfFilteredChallenges,
          numberOfFinished: response.results.numberOfFinished,
          numberOfOnGoing: response.results.numberOfOnGoing,
          numberOfScheduled: response.results.numberOfScheduled,
          numberOfClosed: 0,
          numberOfCurrent: 0,
        };
      } catch (error) {
        this.games = [];
      }
    },

    async loadModeFilter(): Promise<void> {
      this.modeFilter = (await gamesService.getModeFilter()).results;
    },

    async loadTypeFilter(): Promise<void> {
      this.typeFilter = (await gamesService.getTypeFilter()).results;
    },

    async loadGameDetails(gameReference: string): Promise<Game> {
      const gameIndex = this.games.findIndex(
        (game) => game.reference === gameReference,
      );

      const promises: (
        | Promise<GameDetails>
        | Promise<ProcessCompletionDetails>
      )[] = [gamesService.getGameDetails(gameReference)];
      if (
        this.games[gameIndex]?.statusStaticReference === GameStatus.CANCELED ||
        this.games[gameIndex]?.forcedCompleted
      ) {
        promises.push(
          gamesService.getChallengeCompletionDetails(gameReference),
        );
      }

      const details = await Promise.all(promises);

      this.games[gameIndex] = {
        ...this.games[gameIndex],
        ...details[0],
        completionDetails: details[1] as ProcessCompletionDetails,
      };

      return this.games[gameIndex];
    },

    removeGame(reference: string): Promise<{ message: string }> {
      return gamesService.removeGame(reference);
    },

    finishGame(reference: string, motive?: string): Promise<void> {
      return gamesService.finishGame(reference, motive);
    },

    cancelGame(reference: string, motive: string): Promise<void> {
      return gamesService.cancelGame(reference, motive);
    },

    createGame(newGame: NewGameRequest): Promise<void> {
      return gamesService.createGame(newGame);
    },

    editGame(
      editedGame: EditGameRequest,
      gameReference: string,
    ): Promise<{ message: string }> {
      return gamesService.editGame(editedGame, gameReference);
    },

    async getChallengeSchedulerConfig(): Promise<void> {
      try {
        this.challengeSchedulerConfig = (
          await gamesService.getSchedulerChallengeConfig()
        ).results;
      } catch (e) {
        this.challengeSchedulerConfig = [];
      }
    },

    async loadGameModes(
      audienceStaticReference: ChallengeAudienceStaticReference,
    ): Promise<void> {
      try {
        this.gameModes = (
          await gamesService.getGameModes(audienceStaticReference)
        ).results.challengeModes;
      } catch (e) {
        this.gameModes = [];
      }
    },

    async loadGameTypes(
      reference: ChallengeModeStaticReference,
    ): Promise<void> {
      try {
        this.gameTypes = (
          await gamesService.getGameTypes(reference)
        ).results.challengeTypes;
      } catch (e) {
        this.gameTypes = [];
      }
    },

    async loadGamesLeaderboard(
      reference: string,
      limit?: number,
    ): Promise<void> {
      try {
        this.gameLeaderboard = await gamesService.getGameLeaderboard(
          reference,
          limit,
        );
      } catch (e) {
        this.gameLeaderboard = {} as ApiResponse<GameLeaderboardResponse>;
      }
    },

    async loadGameLeaderboard(
      reference: string,
      leaderboardDetailParams: GetRequestParameters = {},
    ): Promise<void> {
      try {
        this.gameLeaderboard = await gamesService.getLeaderboardDetail(
          reference,
          leaderboardDetailParams,
        );
      } catch (e) {
        this.gameLeaderboard = {} as ApiResponse<GameLeaderboardResponse>;
      }
    },

    async loadGameGoals(
      typeReference: ChallengeTypeStaticReference,
    ): Promise<void> {
      try {
        this.gameGoals = (
          await gamesService.getGameGoals(typeReference)
        ).results.challengeGoals;
      } catch (e) {
        this.gameGoals = [];
      }
    },

    async loadGamesDistributionCoins(
      totalCoins: number,
      challengeModeStaticReference: ChallengeModeStaticReference,
      challengeTypeStaticReference?: ChallengeTypeStaticReference,
      totalPlayers?: number,
    ): Promise<void> {
      try {
        this.distributionCoins = await gamesService.getGamesDistributionCoins(
          totalCoins,
          challengeModeStaticReference,
          challengeTypeStaticReference,
          totalPlayers,
        );
      } catch (e) {
        this.distributionCoins = {} as PositionGamesDistribution;
      }
    },

    async loadGamesDistributionPoints(
      duration: number,
      challengeModeStaticReference: ChallengeModeStaticReference,
      challengeTypeStaticReference?: ChallengeTypeStaticReference,
      totalPlayers?: number,
    ): Promise<void> {
      try {
        this.distributionPoints = await gamesService.getGamesDistributionPoints(
          duration,
          challengeModeStaticReference,
          challengeTypeStaticReference,
          totalPlayers,
        );
      } catch (e) {
        this.distributionPoints = {} as PositionGamesDistribution;
      }
    },

    async loadDuplicatedGame(gameReference: string): Promise<void> {
      this.duplicatedGame = await gamesService.getDuplicatedGame(gameReference);
    },

    async loadDuplicatedChallenge(challengeReference: string): Promise<void> {
      this.duplicatedChallenge = await gamesService.getDuplicatedChallenge(
        challengeReference,
      );
    },

    async loadGameAudience(): Promise<void> {
      try {
        this.gameAudience = (
          await gamesService.getGameAudience()
        ).results.audiences;
      } catch (e) {
        this.gameAudience = [];
      }
    },

    getGoalTranslation(
      translate: (
        key: string,
        parameters: (string | number)[] | (string | number),
      ) => string,
      game: {
        typeStaticReference: ChallengeTypeStaticReference;
        goalStaticReference?: ChallengeGoalStaticReference;
        unitMeasureName: string;
        targetAmount?: number;
      },
    ): string {
      const pluralHandler =
        game.typeStaticReference === ChallengeTypeStaticReference.ACHIEVER
          ? 1
          : 0;

      const staticReference =
        game.typeStaticReference === ChallengeTypeStaticReference.CLASSIC
          ? game.goalStaticReference
          : game.typeStaticReference;

      return translate(`games.goalDescription.${staticReference}`, [
        game.targetAmount || pluralHandler,
        translate(
          `enums.${game.unitMeasureName}`,
          game.targetAmount || pluralHandler,
        ),
      ]);
    },

    async loadRecurrencyConfigs(duration: number): Promise<void> {
      try {
        this.recurrencyConfigs = (
          await gamesService.getRecurrencyIntervals(duration)
        ).results.recurrencies;
      } catch (e) {
        this.recurrencyConfigs = [];
      }
    },

    async loadRecurrentPointsMultiplier(): Promise<void> {
      try {
        this.recurrentPointsMultiplier = (
          await gamesService.getRecurrentPointsMultiplier()
        ).recurrentPointsMultiplier;
      } catch (e) {
        this.recurrentPointsMultiplier = 0;
      }
    },

    async loadChallengeFormats(): Promise<void> {
      try {
        this.challengeFormats = await gamesService.getChallengeFormats();
      } catch (e) {
        this.challengeFormats = [];
        this.audienceReferences = {};
      }
    },

    async loadChallengeImage(
      challengeModeStaticReference: string,
      challengeTypeStaticReference: string,
      challengeGoalStaticReference?: string,
    ): Promise<void> {
      try {
        this.challengeImage = (
          await gamesService.getChallengeImage(
            challengeModeStaticReference,
            challengeTypeStaticReference,
            challengeGoalStaticReference,
          )
        ).results;
      } catch (e) {
        this.challengeImage = [] as ChallengeImage[];
      }
    },

    async createChallenge(challenge: NewGameRequest) {
      this.wizardFormError = 0;
      try {
        await gamesService.createGame(challenge);
      } catch (e) {
        if (e instanceof ResponseError) {
          this.wizardFormError = e.reason.code;
        }
        throw e;
      }
    },

    async getCreationChallengeEnabled(): Promise<void> {
      try {
        this.isEnabledCreateChallenge =
          await gamesService.getCreationChallengeEnabled();
      } catch (e) {
        this.isEnabledCreateChallenge = {
          challengeCreationEnabled: false,
          hasMetrics: false,
          hasWorkers: false,
        };
      }
    },

    async loadChallenges(): Promise<void> {
      try {
        const response = await gamesService.getChallenges(this.loadGameRequest);
        this.challenges = response.results.challenges;
        this.challengeCount = {
          numberOfChallenges: response.results.numberOfChallenges,
          numberOfFilteredChallenges:
            response.results.numberOfFilteredChallenges,
          numberOfFinished: response.results.numberOfFinished,
          numberOfOnGoing: response.results.numberOfOnGoing,
          numberOfScheduled: response.results.numberOfScheduled,
          numberOfClosed: response.results.numberOfClosed,
          numberOfCurrent: response.results.numberOfCurrent,
        };
      } catch (error) {
        this.challenges = [];
      }
    },

    async loadChallengeDetails(challengeReference: string): Promise<void> {
      const gameIndex = this.challenges.findIndex(
        (game) => game.reference === challengeReference,
      );

      const promises: (
        | Promise<GameDetails>
        | Promise<ProcessCompletionDetails>
      )[] = [gamesService.getGameDetails(challengeReference)];
      if (
        this.challenges[gameIndex]?.statusStaticReference ===
          GameStatus.CANCELED ||
        this.challenges[gameIndex]?.forcedCompleted
      ) {
        promises.push(
          gamesService.getChallengeCompletionDetails(challengeReference),
        );
      }

      const details = await Promise.all(promises);

      this.challenges[gameIndex] = {
        ...this.challenges[gameIndex],
        ...details[0],
        completionDetails: details[1] as ProcessCompletionDetails,
      };
    },

    async loadParticipantDetail(
      challengeReference: string,
      participantsDetailParameters: GetRequestParameters = {},
    ): Promise<GamesParticipantsDetail> {
      try {
        return await gamesService.getParticipantsDetail(
          challengeReference,
          participantsDetailParameters,
        );
      } catch (e) {
        return {} as GamesParticipantsDetail;
      }
    },

    async loadLeaderBoardDetail(
      reference: string,
      leaderboardDetailParams: GetRequestParameters = {},
    ): Promise<GameLeaderboardResponse> {
      try {
        return (
          await gamesService.getLeaderboardDetail(
            reference,
            leaderboardDetailParams,
          )
        ).results;
      } catch (error) {
        return {} as GameLeaderboardResponse;
      }
    },
  },
});
