import { PositionGamesDistribution } from '@/shared/types/games';

export const getGameDistributionPoints = (): PositionGamesDistribution => ({
  participation: 50,
  firstPlace: 1000,
  secondPlace: 500,
  thirdPlace: 50,
  total: 2900,
  totalPlayers: 30,
});

export const getGameDistributionPointsTogetherAsTeam =
  (): PositionGamesDistribution => ({
    participation: 50,
    win: 2000,
    loss: 0,
    total: 2900,
    totalPlayers: 30,
  });

export const getGameDistributionPointsDuel = (): PositionGamesDistribution => ({
  participation: 50,
  win: 2000,
  loss: 0,
  total: 2900,
  totalPlayers: 30,
});
