import { Image } from './generic';

export enum UserType {
  OPERATOR = 'UT_1',
  MANAGER = 'UT_2',
  ADMIN = 'UT_3',
}

export enum UserStatus {
  ACTIVE = 'S_1',
  DELETED = 'S_2',
  DISABLED = 'S_18',
  INACTIVE = 'S_13',
  INVITED = 'S_17',
}

export interface Status {
  reference: string;
  name: string;
}

export interface SiteUser {
  name: string;
  reference: string;
  city: string;
  country: string;
  timezone: {
    utcOffset: string;
    ianaTimezone: string;
  };
}

export interface User {
  name: string;
  reference: string;
  orgUserId?: string;
  avatarUrl: string;
  email: string;
  userTypeStaticReference: UserType;
  userStatusStaticReference: UserStatus;
  privacyPolicy: boolean;
  termsAndConditions: boolean;
  lastEntrance?: number;
  numberOfChallenges: number;
  teamNames: string;
  team?: {
    reference: string;
    name: string;
  };
  site: SiteUser;
  coins: number;
}

export interface RegisterUser {
  email: string;
  name: string;
  orgUserId: string;
}

export interface EditUser {
  name: string;
  email?: string;
  orgUserId?: string;
  userTypeStaticReference: UserType;
  userStatusStaticReference: UserStatus;
  team?: {
    reference: string;
    name: string;
  };
  teamReference: string | undefined;
  siteReference: string;
}

export interface UserSidebarEditErrors {
  name: string;
  orgUserId: string;
  siteReference: string;
}

export interface EditTeamErrors {
  name: string;
  orgTeamId: string;
}

export interface EditTeamRequest {
  name: string;
  orgTeamId: string;
  description: string;
  imageReference?: string;
}

export interface NuvoUserRow {
  row: number;
  name: string;
  email: string;
  orgUserId?: string;
  userTypeStaticReference?: UserType;
  siteExternalReference: string;
}

export interface UserErrorListItem {
  code: number;
  message: string;
  field:
    | 'userTypeStaticReference'
    | 'orgUserId'
    | 'email'
    | 'name'
    | 'siteExternalReference';
}

export interface UserError {
  row: number;
  list: UserErrorListItem[];
}

export interface ChallengeParticipant {
  participantOrgId: string;
  name: string;
}

export interface Operator {
  orgUserId?: string;
  reference: string;
  name: string;
}

export interface NewTeam {
  name: string;
  imageReference?: string;
  orgTeamId: string;
  description: string;
}

export interface NewTeamErrors {
  name: string;
  orgTeamId: string;
}

export interface Team {
  name: string;
  reference: string;
  image: Image;
  description?: string;
  orgTeamId?: string;
  numberOfUsers?: number;
  createdAt?: number;
  updatedAt?: number;
}

export interface ChangeTeamRequest {
  userReferences: string[];
  teamReference: string;
}

export interface ChangeSiteRequest {
  userReferences: string[];
  siteReference: string;
}

export interface CreateUserRequestBody {
  name: string;
  orgUserId?: string;
  email: string;
  userTypeStaticReference: UserType;
  teamReference?: string;
  siteExternalReference: string;
  siteReference: string;
}

export interface CreateUserErrors {
  name: string;
  orgUserId: string;
  email: string;
  userTypeStaticReference: string;
  siteReference: string;
}

export interface GetAllValidOperatorsResponse {
  teams: Array<{
    name: string;
    orgTeamId: string;
    reference: string;
    operators: Array<{
      name: string;
      orgUserId: string;
    }>;
  }>;
  operatorsWithNoTeams: Array<{
    name: string;
    orgUserId: string;
  }>;
}

export interface RequestUsersTimezones {
  workerReferences?: string[];
  teamReferences?: string[];
  audience?: string;
  startDate?: number;
  endDate?: number;
}

export interface UserInfo {
  name: string;
  reference: string;
  orgUserId: string;
}

export interface UsersSites {
  siteName: string;
  siteCity: string;
  siteCountry: string;
  siteOffset: string;
  users: UserInfo[];
}

export interface LoadUsersRequest {
  page: number;
  size: number;
  teams?: string[];
  roles?: UserType[];
  query?: string;
  orderBy?: 'ASC' | 'DESC';
  sortBy?: string;
  statuses?: UserStatus[];
  noTeams: boolean;
}

export interface LoadUsersResponse {
  users: User[];
  numberOfUsers: number;
  numberOfUsersFiltered: number;
}

export interface UsersCount {
  numberOfUsers: number;
  numberOfTeams: number;
}
