// PNGs
export const vaibeLogoSmall = '/img/vaibe-logo-small.svg';
export const vaibeLogo = '/img/vaibe-logo.svg';
export const crown = '/img/crown.png';
export const defaultItemIllustration = '/img/default-item-illustration.png';
export const noConnection = '/img/no-connection.png';
export const sadEmoji = '/img/sad-emoji.png';
export const surveysSecondCard = '/img/surveys-second-card.png';
export const surveysThirdCard = '/img/surveys-third-card.png';
export const tieIcon = '/img/tie.png';
export const leaderboardLoading = '/img/leaderboard-loading.png';
export const leaderboardLoadingDuel = '/img/leaderboard-loading-duel.png';

// FTX
export const book = '/img/book.svg';

// placeholders
export const iconGoldPicker = '/img/gold-picker.svg';
export const iconPlaceholderImage = '/img/placeholder-image.svg';
