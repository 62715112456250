export enum MetricStatusColor {
  SEM6 = '#CCE6CF',
  SEM7 = '#E3EEFF',
}

export enum MetricStatusStaticReference {
  ACTIVE = 'S_1',
  INACTIVE = 'S_13',
}

export enum MetricInputMethod {
  API = 'S_API',
  FILE = 'S_FILE',
}

export enum MetricTypes {
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

export enum ChartMetricFilter {
  ALL_TIME = 'ALL_TIME',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface Category {
  reference: string;
  name: string;
  description: string;
  numberOfMetrics: number;
  createdAt: number;
  updatedAt: number;
  categoryType?: {
    reference: string;
    name: string;
  };
}

export interface UnitOfMeasure {
  reference: string;
  name: string;
}

export interface Metric {
  reference: string;
  category: {
    id?: number;
    name?: string;
    reference?: string;
  };
  unitMeasures: UnitOfMeasure[];
  transactionCode: string;
  name: string;
  description: string;
  inputMethod: {
    staticReference: MetricInputMethod;
    name: string;
  };
  status: {
    staticReference: MetricStatusStaticReference;
    name: string;
  };
  metricTypeName: string;
  metricTypeReference: string;
  hasGame: boolean;
  baseline?: number;
  safetyFeature: boolean;
}

export interface CategoriesSidebarErrors {
  name: string;
}

export interface MetricSidebarEditErrors {
  name: string;
  description: string;
  category: string;
  unitMeasure: string;
}

export interface AddMetricErrors {
  name: string;
  description: string;
  transactionCode: string;
  sourceStaticReference: string;
  metricTypeName: string;
  unitMeasure: string;
}

export interface EditMetric {
  name: string;
  description: string;
  categoryReference: string | undefined;
  unitMeasureReference: string[];
  sourceStaticReference: MetricInputMethod;
  statusStaticReference: MetricStatusStaticReference;
  baseline?: number;
  metricTypeName: string;
  metricTypeReference: string;
  safetyFeature: boolean;
}

export interface CreateMetricBody {
  name: string;
  categoryReference: string;
  unitMeasureReference: string[];
  description: string;
  transactionCode: string;
  sourceStaticReference: string;
  metricTypeName: string;
  baseline?: number;
  safetyFeature: boolean;
}

export interface CreateMetricRequestBody {
  name: string;
  categoryReference: string;
  unitMeasureReference: string[];
  description: string;
  transactionCode: string;
  sourceStaticReference: string;
  metricTypeName: string;
  metricTypeReference: string;
  baseline?: number;
  safetyFeature: boolean;
}

export interface GroupedMetric {
  reference: string;
  name: string;
  transactionCode: string;
  type: {
    reference: string;
    name: string;
  };
}

export interface GroupedCategory {
  category: {
    reference: string;
    name: string;
    categoryType: {
      reference: string;
      name: string;
    };
    metrics: GroupedMetric[];
  };
}

export interface CategorizedMetrics {
  categories: GroupedCategory[];
  uncategorizedMetrics: GroupedMetric[];
}

export interface CategorizedMetricsMap {
  categories: Record<string, GroupedCategory>;
  uncategorizedMetrics: Record<string, GroupedMetric>;
}

export interface ChartMetricsPoints {
  x: number;
  y: number;
}

export interface ChartMetricsResponse {
  chartPoints: ChartMetricsPoints[];
  lastUpdatedPoint: number | null;
}

export interface LastSearchMetricsResponse {
  chartData: {
    histogramPoints: ChartMetricsPoints[];
    lastUpdatedPoint: number;
  };
  searchParameters: {
    all: boolean;
    categoryReference: string;
    teamReference: string;
    dateInterval: ChartMetricFilter;
    externalEntitiesIds: string[];
    metricsTransactionCodes: string[];
    teamMetrics?: boolean;
    unitMeasureReference: string;
  };
}

export interface MetricCategorizedFilter
  extends Omit<GroupedMetric, 'transactionCode' | 'name' | 'type'> {
  label: string;
  type: string;
}

export type CreateCategoryRequestParameters = Pick<
  Category,
  'name' | 'description'
>;

export type EditCategoryRequestBody = Pick<Category, 'name' | 'description'>;

export interface GetChartPointsRequestBody {
  all: boolean;
  teamMetrics: boolean;
  teamReference: string;
  categoryReference: string;
  unitMeasureReference: string;
  externalEntitiesIds: string[];
  dateInterval: ChartMetricFilter;
  metricsTransactionCodes: string[];
}

export interface SimplifiedCategory {
  name: string;
  reference: string;
}

export interface MetricCategoryType {
  reference: string;
  name: string;
}

export interface LoadMetricRequest {
  page: number;
  size: number;
  categories?: string[];
  query?: string;
  orderBy?: 'ASC' | 'DESC';
  sortBy?: string;
  statuses?: MetricStatusStaticReference[];
  noCategories: boolean;
}

export interface LoadMetricsResponse {
  metrics: Metric[];
  totalMetrics: number;
  totalFilteredMetrics: number;
}

export interface MetricsCount {
  numberOfMetrics: number;
  numberOfCategories: number;
}
