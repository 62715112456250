import qs from 'qs';
import { ApiResponse, ProcessCompletionDetails } from '@/shared/types/generic';
import {
  NewGameRequest,
  ChallengeMode,
  Game,
  EditGameRequest,
  PositionGamesDistribution,
  GameDetails,
  SchedulerChallengeConfig,
  ChallengeGoal,
  ChallengeAudience,
  ChallengeType,
  RecurrencyOption,
  GameLeaderboardResponse,
  LoadGameRequest,
  LoadGameResponse,
  ChallengeTypeStaticReference,
  ChallengeModeStaticReference,
  ChallengeAudienceStaticReference,
  CreateChallengeEnabled,
  GamesParticipantsDetail,
} from '@/shared/types/games';
import {
  ChallengeFormatOptions,
  ChallengeImage,
  IChallenge,
  LoadChallengeResponse,
} from '@/shared/types/wizard';
import axios from '@/plugins/http/axios';
import { GetRequestParameters } from '@/shared/types/requests';

export default {
  getGames(request: LoadGameRequest): Promise<ApiResponse<LoadGameResponse>> {
    return axios.get('/challenges', {
      params: {
        page: request.page,
        size: request.size,
        categories: request.categories,
        metrics: request.metrics,
        modes: request.modes,
        query: request.query,
        order_by: request.sortBy ? request.orderBy : undefined,
        sort_by: request.sortBy,
        statuses: request.statuses,
        types: request.types,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  getGameDetails(gameReference: string): Promise<GameDetails> {
    return axios.get(`/challenges/detail/${gameReference}`);
  },

  getChallengeCompletionDetails(
    gameReference: string,
  ): Promise<ProcessCompletionDetails> {
    return axios.get(`/challenge-completions/${gameReference}`);
  },

  getModeFilter(): Promise<ApiResponse<ChallengeModeStaticReference[]>> {
    return axios.get('/challenge-modes/challenge');
  },

  getTypeFilter(): Promise<ApiResponse<ChallengeTypeStaticReference[]>> {
    return axios.get('/challenge-types/challenge');
  },

  removeGame(reference: string): Promise<{ message: string }> {
    return axios.delete(`/challenges/${reference}`);
  },

  finishGame(reference: string, motive?: string): Promise<void> {
    return axios.post(`/challenges/finish/${reference}`, { motive });
  },

  cancelGame(reference: string, motive: string): Promise<void> {
    return axios.post(`/challenges/cancel/${reference}`, { motive });
  },

  createGame(newGame: NewGameRequest): Promise<void> {
    return axios.post('/challenges', newGame);
  },

  editGame(
    game: EditGameRequest,
    gameReference: string,
  ): Promise<{ message: string }> {
    return axios.patch(`/challenges/${gameReference}`, game);
  },

  getGameModes(
    audienceStaticReference: ChallengeAudienceStaticReference,
  ): Promise<ApiResponse<{ challengeModes: ChallengeMode[] }>> {
    return axios.get(
      `/challenge-modes/challenge-audience/${audienceStaticReference}`,
    );
  },

  getGameTypes(
    modeReference: ChallengeModeStaticReference,
  ): Promise<ApiResponse<{ challengeTypes: ChallengeType[] }>> {
    return axios.get(`/challenge-types/challenge-mode/${modeReference}`);
  },

  getGameGoals(
    typeReference: ChallengeTypeStaticReference,
  ): Promise<ApiResponse<{ challengeGoals: ChallengeGoal[] }>> {
    return axios.get(`/challenge-goals/challenge-type/${typeReference}`);
  },

  getGamesDistributionCoins(
    totalCoins: number,
    challengeModeStaticReference: ChallengeModeStaticReference,
    challengeTypeStaticReference?: ChallengeTypeStaticReference,
    totalPlayers?: number,
  ): Promise<PositionGamesDistribution> {
    return axios.post('/challenge-rewards/coins', {
      totalCoins,
      challengeModeStaticReference,
      challengeTypeStaticReference,
      totalPlayers,
    });
  },

  getGamesDistributionPoints(
    duration: number,
    challengeModeStaticReference: ChallengeModeStaticReference,
    challengeTypeStaticReference?: ChallengeTypeStaticReference,
    totalPlayers?: number,
  ): Promise<PositionGamesDistribution> {
    return axios.post('/challenge-rewards/points', {
      duration,
      challengeModeStaticReference,
      challengeTypeStaticReference,
      totalPlayers,
    });
  },

  getSchedulerChallengeConfig(): Promise<
    ApiResponse<SchedulerChallengeConfig[]>
  > {
    return axios.get('/challenge-scheduler-config');
  },

  getGameLeaderboard(
    reference: string,
    limit?: number,
  ): Promise<ApiResponse<GameLeaderboardResponse>> {
    return axios.get(`/leaderboards/challenge/${reference}`, {
      params: { limit },
    });
  },

  getDuplicatedGame(gameReference: string): Promise<Game> {
    return axios.get(`/challenges/clone/${gameReference}`);
  },

  getDuplicatedChallenge(challengeReference: string): Promise<IChallenge> {
    return axios.get(`/challenges/clone/${challengeReference}`);
  },

  getGameAudience(): Promise<ApiResponse<{ audiences: ChallengeAudience[] }>> {
    return axios.get('/audiences');
  },

  getRecurrencyIntervals(
    duration: number,
  ): Promise<ApiResponse<{ recurrencies: RecurrencyOption[] }>> {
    return axios.get('/recurrency', {
      params: { duration },
    });
  },

  getRecurrentPointsMultiplier(): Promise<{
    recurrentPointsMultiplier: number;
  }> {
    return axios.get('/challenge-rewards/recurrent-points-multiplier');
  },

  getChallengeFormats(): Promise<ChallengeFormatOptions[]> {
    return axios.get('/challenge-modes-types');
  },

  getChallengeImage(
    challengeModeStaticReference: string,
    challengeTypeStaticReference: string,
    challengeGoalStaticReference?: string,
  ): Promise<ApiResponse<ChallengeImage[]>> {
    return axios.post('/challenge-images', {
      challengeModeStaticReference,
      challengeTypeStaticReference,
      challengeGoalStaticReference,
    });
  },

  getCreationChallengeEnabled(): Promise<CreateChallengeEnabled> {
    return axios.get('/challenges/creation/enabled');
  },

  getChallenges(
    request: LoadGameRequest,
  ): Promise<ApiResponse<LoadChallengeResponse>> {
    return axios.get('/challenges', {
      params: {
        page: request.page,
        size: request.size,
        categories: request.categories,
        metrics: request.metrics,
        modes: request.modes,
        query: request.query,
        order_by: request.sortBy ? request.orderBy : undefined,
        sort_by: request.sortBy,
        statuses: request.statuses,
        types: request.types,
        modeTypes: request.modeTypes,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  getParticipantsDetail(
    challengeReference: string,
    { page, size, orderBy }: GetRequestParameters = {},
  ): Promise<GamesParticipantsDetail> {
    return axios.get(`/challenges/detail/${challengeReference}/participants`, {
      params: {
        page,
        size,
        order_by: orderBy,
      },
    });
  },

  getLeaderboardDetail(
    reference: string,
    {
      page,
      size,
      orderBy,
      showIncomingRewards,
      showAsTeamLeaderboard,
      limit,
    }: GetRequestParameters = {},
  ): Promise<ApiResponse<GameLeaderboardResponse>> {
    return axios.get(`/leaderboards/challenge/${reference}`, {
      params: {
        page,
        size,
        order_by: orderBy,
        limit,
        showIncomingRewards,
        showAsTeamLeaderboard,
      },
    });
  },
};
