<template>
  <AppLayout v-if="requiresAuthentication">
    <FirstTimeExperience v-if="showFTXModals" />
    <router-view />
  </AppLayout>
  <router-view v-else />
  <AppLoader
    :state="sharedStore.loaderState"
    :target="sharedStore.loaderTarget"
    :transparent="sharedStore.loaderTransparent"
    :text="sharedStore.loaderText"
  />
  <AppGlobalConfirmationModal />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiChevronRight } from '@quasar/extras/mdi-v6';
import AppLayout from '@/components/layouts/AppLayout/AppLayout.vue';
import { mapStores } from 'pinia';
import {
  Feature,
  HttpErrorCodes,
  Locale,
  RouteAuthenticationRequirement,
} from '@/shared/types/generic';
import useUserStore from '@/store/user/useUserStore';
import useTokenStore from '@/store/token/useTokenStore';
import useStoreStore from '@/store/store/useStoreStore';
import useProfileStore from '@/store/profile/useProfileStore';
import useMetricsStore from '@/store/metrics/useMetricsStore';
import useLeaderboardStore from '@/store/leaderboard/useLeaderboardStore';
import useGamesStore from '@/store/games/useGamesStore';
import useFilesStore from '@/store/files/useFilesStore';
import useErrorStore from '@/store/error/useErrorStore';
import useSharedStore from '@/store/shared/useSharedStore';
import { isEmpty } from '@/shared/helpers/validators/validators';
import getQuasarLang from '@/shared/helpers/getQuasarLang/getQuasarLang';
import { RouteLocationMatched } from 'vue-router';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import useFeatures from '@/composables/useFeatures/useFeatures';
import CryptoJS from 'crypto-js';
import i18n from './plugins/i18n/i18n';
import usePublicDisplaysStore from './store/publicDisplays/usePublicDisplaysStore';
import useTranslationsStore from './store/translations/useTranslationsStore';
import usePosHogConfig from './composables/usePostHogConfig/usePostHogConfig';
import AppGlobalConfirmationModal from './components/app/AppGlobalConfirmationModal/AppGlobalConfirmationModal.vue';
import AppLoader from './components/app/AppLoader/AppLoader.vue';
import FirstTimeExperience from './components/ftx/FirstTimeExperience.vue';
import useLogout from './composables/useLogout/useLogout';

export default defineComponent({
  name: 'App',

  components: {
    AppLayout,
    AppGlobalConfirmationModal,
    AppLoader,
    FirstTimeExperience,
  },

  setup() {
    const { isFeatureEnabled } = useFeatures();
    const { logout } = useLogout();

    return { isFeatureEnabled, logout };
  },

  data() {
    return {
      mdiChevronRight,
      routeName: '',
      showWelcomeModals: true,
    };
  },

  computed: {
    ...mapStores(
      useFilesStore,
      useGamesStore,
      useLeaderboardStore,
      useMetricsStore,
      useProfileStore,
      useStoreStore,
      useTokenStore,
      useUserStore,
      useErrorStore,
      usePublicDisplaysStore,
      useTranslationsStore,
      useSharedStore,
    ),

    token(): string | undefined {
      return this.tokenStore.token;
    },

    errorCode(): HttpErrorCodes | undefined {
      return this.errorStore.errorCode;
    },

    requiresAuthentication(): boolean {
      return this.$route.matched.some(
        (route) =>
          route.meta.authenticationRequirement ===
          RouteAuthenticationRequirement.AUTHENTICATED,
      );
    },

    userProfileLanguage(): string {
      return (
        this.profileStore.userProfile?.userConfig.userLanguage || Locale.US
      );
    },

    showFTXModals(): boolean | undefined {
      return (
        this.profileStore.userProfile?.userConfig.showFtx &&
        this.isFeatureEnabled(Feature.FTX)
      );
    },

    isIntercomEnabled(): boolean {
      return String(process.env.VUE_APP_INTERCOM_ENABLED) === 'true';
    },
  },

  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(): void {
        this.updateTitle();
      },
    },

    '$route.matched': {
      immediate: true,
      handler(newVal): void {
        const routes = newVal.filter((match: RouteLocationMatched) => {
          return !match.meta.firstLevel && match.name;
        });

        this.routeName = String(routes[routes.length - 1].name);

        this.updateTitle();
      },
    },

    errorCode: {
      immediate: true,
      handler(newErrorCode?: HttpErrorCodes) {
        switch (newErrorCode) {
          case HttpErrorCodes.FORBIDDEN:
          case HttpErrorCodes.INACTIVE_USER:
          case HttpErrorCodes.UNAUTHORIZED_USER:
            this.logout();
            break;
          case HttpErrorCodes.SERVER_MAINTENANCE:
          case HttpErrorCodes.NO_CONNECTION:
            this.$router.push({
              path: `/${newErrorCode}`,
              query: { bypass: 'true' },
            });
            break;
          default:
            break;
        }

        this.errorStore.setErrorCode();
      },
    },

    token(newToken?: string) {
      if (isEmpty(newToken) && !this.$route.path.includes('farewell')) {
        this.logout();
      }
    },

    userProfileLanguage: {
      immediate: true,
      handler(newUserProfileLanguage: Locale.US) {
        this.$i18n.locale = newUserProfileLanguage;
        i18n.global.locale.value = newUserProfileLanguage;
        this.setQuasarLang(newUserProfileLanguage);
      },
    },

    'profileStore.userProfile': {
      handler(newValue, oldValue) {
        if (this.isIntercomEnabled) {
          if (oldValue !== undefined) {
            window.Intercom('shutdown');
          }
          this.launchIntercom();
        }
      },
    },
  },

  mounted(): void {
    usePosHogConfig().loadToolbar();

    if (this.isIntercomEnabled) {
      const script = document.createElement('script');
      script.src = `${process.env.VUE_APP_INTERCOM_HOST}/${process.env.VUE_APP_INTERCOM_KEY}`;
      script.async = false;
      script.addEventListener('load', this.launchIntercom);

      document.body.appendChild(script);
    }

    document.addEventListener('click', (event) => {
      const clickedElement = event.target as HTMLElement;
      const element = clickedElement.localName;
      const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
      this.saveAutoCapturePostHog(eventName || '', true);
    });
  },

  beforeUnmount(): void {
    window.removeEventListener('beforeunload', (event) => {
      const clickedElement = event.target as HTMLElement;
      const element = clickedElement.localName;
      const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
      this.saveAutoCapturePostHog(eventName || '', false);
    });
  },

  methods: {
    saveAutoCapturePostHog(eventName: string, execute: boolean): void {
      if (execute) {
        usePostHogEvents().postHogAutoCaptureEvent(eventName, {
          identifier: this.profileStore.userProfile?.user.orgName as string,
        });
      }
    },

    setQuasarLang(lang: string): void {
      const selectedLang = getQuasarLang(lang as Locale);
      import(
        /* webpackInclude: /(pt|en-US|de-DE|es)\.js$/ */
        `quasar/lang/${selectedLang}`
      ).then((quasarLang) => {
        this.$q.lang.set(quasarLang.default);
      });
    },

    updateTitle(): void {
      if (this.routeName) {
        const title = this.$t(`nav.${this.routeName}`);

        if (!title.includes('nav.')) {
          document.title = `${this.$t('nav.manager')} - ${title}`;
        } else {
          document.title = 'Vaibe Manager';
        }
      }
    },

    launchIntercom() {
      if (this.isIntercomEnabled) {
        if (!this.profileStore.userProfile) {
          window.Intercom('boot', {
            app_id: process.env.VUE_APP_INTERCOM_KEY,
          });
          return;
        }

        const hmac = CryptoJS.HmacSHA256(
          this.profileStore.userProfile?.user.email,
          process.env.VUE_APP_INTERCOM_HASH_KEY,
        );

        window.Intercom('shutdown');
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_KEY,
          name: this.profileStore.userProfile?.user.username || '',
          user_hash: hmac.toString(),
          email: this.profileStore.userProfile?.user.email || '',
          company: {
            name: this.profileStore.userProfile?.user.orgName || '',
            id: this.profileStore.userProfile?.user.orgUserId || '',
          },
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
#posthog-feedback-button {
  background-color: $black;
  border-radius: 0px;
  position: fixed;
  bottom: 16%;
  transform: rotate(-90deg) translate(0px, 100%);
  right: 0px;
  border-radius: 4px;
  color: $white;
}
</style>
